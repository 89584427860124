import {
  CardImageLayoutSkeleton,
  CardWithImageLayout,
} from '../layouts/card-image-layout';
import logo from '../../assets/CareWays-logo.png';
import { Module } from '@/lib/models';
import { DefaultItemsLayout } from '../layouts/default-items-layout';

export interface ModuleCardProps {
  module: Module;
  onClick: () => void;
}

export const ModuleCard = ({ module, onClick }: ModuleCardProps) => {
  return (
    <CardWithImageLayout
      title={module.name}
      description={module.description}
      image={module.image ?? logo}
      action={onClick}
    />
  );
};

export const ModuleCardSkeleton = () => {
  return <CardImageLayoutSkeleton />;
};

export const ModuleCardListSkeleton = () => (
  <DefaultItemsLayout>
    {Array(5)
      .fill(0)
      .map((_, idx) => (
        <ModuleCardSkeleton key={idx} />
      ))}
  </DefaultItemsLayout>
);
