import { CheckCircle, Circle, MinusCircle } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useState } from 'react';
import { AnalyticsStats, DataKeys, KeyType } from './analytics-stats';
import {
  CourseStatProperties,
  CourseStats,
  CourseStatsByModule,
} from '@/lib/queries/analytics/analytics-course';
import { StackedBarChartData } from '../ui/charts/stacked-bar-chart';
import { ChartConfig } from '../ui/chart';
import { useNavigate } from '@tanstack/react-router';

const metrics: (keyof CourseStatProperties)[] = [
  'media_completed',
  'media_started_not_completed',
  'media_never_started',
] as const;
type MetricTypes = (typeof metrics)[number];

interface CoursesAnalyticsProps {
  stats: {
    byModule: CourseStatsByModule[];
    total: CourseStats[];
  };
}

export const CoursesAnalytics = ({ stats }: CoursesAnalyticsProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'analytics.courseStats',
  });

  const navigate = useNavigate();

  const [selectedMetrics, setSelectedMetrics] =
    useState<MetricTypes[]>(metrics);

  const [selectedCourse, setSelectedCourse] = useState<string>(
    Array.from(
      new Set(stats.byModule.map((moduleStats) => moduleStats.course_name)),
    )[0],
  );

  const selectedCourseId = useMemo(() => {
    return stats.byModule.find(
      (courseStat) => courseStat.course_name === selectedCourse,
    )?.course_id;
  }, [selectedCourse]);

  const courses = useMemo(() => {
    const allCourses = Array.from(
      new Set(stats.byModule.map((moduleStats) => moduleStats.course_name)),
    );
    return allCourses;
  }, [stats]);

  const totalData = useMemo(() => {
    return stats.total.find(
      (moduleStats) => moduleStats.course_name === selectedCourse,
    )!;
  }, [selectedCourse, stats]);

  const mappedMetrics = useMemo(() => {
    return metrics.map((metric) => ({
      label: t(`metrics.${metric}`),
      value: metric,
    }));
  }, [t]);

  const navigateAction = useCallback(
    (stat: string) => {
      return () => {
        if (selectedCourseId)
          navigate({
            to: '/analytics/courses/$courseId/table/$stat',
            params: {
              courseId: selectedCourseId.toString(),
              stat: stat,
            },
          });
      };
    },
    [selectedCourseId, navigate],
  );

  const metricsMap: Record<MetricTypes, DataKeys> = useMemo(
    () => ({
      media_completed: {
        icon: CheckCircle,
        translationKey: 'completed',
        action: navigateAction('media_completed'),
      },
      media_started_not_completed: {
        icon: Circle,
        translationKey: 'notCompleted',
        action: navigateAction('media_started_not_completed'),
      },
      media_never_started: {
        icon: MinusCircle,
        translationKey: 'notStarted',
        action: navigateAction('media_never_started'),
      },
    }),
    [navigateAction],
  );

  const data: StackedBarChartData[] = useMemo(() => {
    return stats.byModule
      .filter((moduleStats) => moduleStats.course_name === selectedCourse)
      .map((moduleStats) => ({
        name: moduleStats.module_name,
        data: selectedMetrics.reduce(
          (acc, selectedMetric) => ({
            ...acc,
            [selectedMetric]: moduleStats[selectedMetric],
          }),
          {},
        ),
      }));
  }, [selectedCourse, stats, selectedMetrics]);

  const keys = useMemo(() => {
    return metrics.reduce(
      (acc, key, index) => {
        const opacity = 1 - 0.5 * (index / 2) ** 1.4;
        acc.push({
          name: key,
          label: t(`metrics.${key}`),
          opacity: opacity,
        });
        return acc;
      },
      [] as KeyType<CourseStatProperties, keyof CourseStatProperties>[],
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const config = useMemo(() => {
    const selectedKeys = keys.filter((key) =>
      selectedMetrics.includes(key.name),
    );

    return selectedKeys.reduce(
      (acc, curr) => ({
        ...acc,
        [`data.${curr.name}`]: {
          label: curr.label,
          color: `hsl(var(--chart-bar-color) / ${curr.opacity})`,
        },
      }),
      {} as ChartConfig,
    );
  }, [selectedMetrics, keys]);

  return (
    <AnalyticsStats
      groupingSelection={selectedCourse}
      setGroupingSelection={setSelectedCourse}
      translate={t}
      totalOptions={courses}
      groupingsMap={metricsMap}
      totalData={totalData}
      data={data}
      setMetrics={setSelectedMetrics}
      metrics={selectedMetrics}
      metricOptions={mappedMetrics}
      config={config}
    />
  );
};
