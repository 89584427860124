import { useMutation } from '@tanstack/react-query';
import { UserEvent } from '../models';
import api from './client';
import { create, Batcher, windowScheduler } from '@yornaath/batshit';

type BatchEventsResponse = {
  code: string;
  message: string;
};

const eventsBatcher: Batcher<BatchEventsResponse, UserEvent> = create({
  fetcher: sendEvents,
  resolver: (response) => response, // we are not really interested in the response
  scheduler: windowScheduler(3 * 1000), // schedule every 3 seconds for now
});

async function sendEvents(events: UserEvent[]): Promise<BatchEventsResponse> {
  const result = await api.post('/events', events);
  return result.data;
}

export function useEventTracking() {
  const mutation = useMutation({
    mutationFn: (event: UserEvent) => eventsBatcher.fetch(event),
    onError: (error) => {
      console.error('Failed to track event:', error);
    },
    throwOnError: false,
    mutationKey: ['events'],
  });

  return {
    track: mutation.mutate,
  };
}
