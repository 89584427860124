import axios from 'axios';
import { getUserToken } from '@/lib/firebase';
import config from '@/lib/config';

const api = axios.create({
  baseURL: `${config.backendUrl}/api/v1`,
});

api.interceptors.request.use(
  async (config) => {
    const token = await getUserToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

export type APIResponse<T> = {
  code: string;
  message: string;
  data: T;
};

export default api;
