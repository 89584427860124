import { UsersAnalyticsTable } from '@/components/analytics/table/users-analytics-table';
import { TableComponentSkeleton } from '@/components/table/table-component';
import { AxiosErrorComponent } from '@/components/ui/errors/axios-error';
import { userStatsFullyQueryOptions } from '@/lib/queries/analytics/analytics-users';
import { useSuspenseQuery } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute(
  '/analytics/_layout_table/users/table/$stat',
)({
  component: AnalyticsUsersTable,
  loader: ({ context: { queryClient }, params }) =>
    queryClient.ensureQueryData(userStatsFullyQueryOptions(params.stat)),
  errorComponent: AxiosErrorComponent,
  pendingComponent: AnalyticsUsersTableSkeleton,
});

function AnalyticsUsersTable() {
  const { stat } = Route.useParams();

  const {
    data: { users },
  } = useSuspenseQuery(userStatsFullyQueryOptions(stat));

  return <UsersAnalyticsTable users={users} />;
}

function AnalyticsUsersTableSkeleton() {
  return <TableComponentSkeleton />;
}
