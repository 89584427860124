import { Button } from '@/components/ui/button';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@/components/ui/collapsible';
import { useTranslation } from 'react-i18next';

export interface TranscriptProps {
  isTranscriptOpen: boolean;
  setIsTranscriptOpen: (isOpen: boolean) => void;
  transcript: string;
}

export const Transcript = ({
  isTranscriptOpen,
  setIsTranscriptOpen,
  transcript,
}: TranscriptProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'course.video' });

  return (
    <Collapsible open={isTranscriptOpen} onOpenChange={setIsTranscriptOpen}>
      <div className="p-3 bg-muted rounded">
        {!isTranscriptOpen && (
          <h4 className="text-sm font-semibold">
            {transcript.slice(0, 235)}
            <CollapsibleTrigger asChild>
              <Button
                variant="ghost"
                className="w-fit bg-transparent p-0 pl-2 !m-0 relative right-[6px] bg-gradient-to-r from-0% from-transparent to-10% to-muted hover:bg-inherit h-4"
              >
                <span>{t('transcript.show')}</span>
              </Button>
            </CollapsibleTrigger>
          </h4>
        )}
        <CollapsibleContent className="space-y-2 text-sm font-semibold flex flex-col">
          {transcript}
          <CollapsibleTrigger asChild>
            <Button
              variant="ghost"
              className="w-fit bg-transparent !m-0 hover:bg-inherit p-0 h-7"
            >
              <span>{t('transcript.hide')}</span>
            </Button>
          </CollapsibleTrigger>
        </CollapsibleContent>
      </div>
    </Collapsible>
  );
};
