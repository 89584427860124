import { Skeleton } from '@/components/ui/skeleton';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { createFileRoute, Outlet, useNavigate } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

export const Route = createFileRoute('/analytics/_layout_analytics')({
  component: LayoutAnalytics,
  loader: ({ location }) => {
    return { tab: location.pathname.split('/').pop() };
  },
  pendingComponent: LayoutAnalyticsSkeleton,
});

const analyticsPages = ['courses', 'users'] as const;
type Pages = (typeof analyticsPages)[number];

function LayoutAnalytics() {
  const navigate = useNavigate();

  const { t } = useTranslation('translation', { keyPrefix: 'analytics' });

  const { tab } = Route.useLoaderData();

  const navigateToAnalytics = (key: Pages) => {
    const link = `/analytics/${key}`;
    navigate({ to: link });
  };

  return (
    <div className="flex flex-col w-full gap-4">
      <span className="text-3xl font-bold">{t('title')}</span>
      <Tabs defaultValue={tab} className="w-full">
        <TabsList className="grid grid-cols-2 w-fit">
          {analyticsPages.map((key) => (
            <TabsTrigger
              key={key}
              className="px-8"
              value={key}
              onClick={() => navigateToAnalytics(key)}
            >
              {t(key)}
            </TabsTrigger>
          ))}
        </TabsList>
      </Tabs>
      <Outlet />
    </div>
  );
}

function LayoutAnalyticsSkeleton() {
  const { t } = useTranslation('translation', { keyPrefix: 'analytics' });

  return (
    <div className="flex flex-col w-full gap-4">
      <span className="text-3xl font-bold">{t('title')}</span>
      <Skeleton className="w-72 h-10" />
      <Outlet />
    </div>
  );
}
