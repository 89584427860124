import { cn } from '@/lib/utils';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from '../../ui/form';
import { useStepper } from '../../ui/stepper';
import { SurveyFormActions } from '../form-actions';
import { FormDataProps } from '../registration-survey';
import { RadioGroup, RadioGroupItem } from '../../ui/radio-group';
import { useState } from 'react';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '../../ui/alert-dialog';

const ChildCareSelectionFormSchema = z
  .object({
    is_parent_or_childcare_provider: z.boolean(),
  })
  .required();
type ChildCareSelectionFormData = z.infer<typeof ChildCareSelectionFormSchema>;

export const ChildCareSelectionForm = ({
  updateDataCallback,
  className,
  disableActions,
  formData,
}: FormDataProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'survey' });
  const { t: tShared } = useTranslation('translation', { keyPrefix: 'shared' });
  const { t: tError } = useTranslation('translation', {
    keyPrefix: 'error.form',
  });

  const { nextStep } = useStepper();

  const [open, setOpen] = useState(false);

  const form = useForm<ChildCareSelectionFormData>({
    resolver: zodResolver(ChildCareSelectionFormSchema),
    defaultValues: {
      is_parent_or_childcare_provider: formData.is_parent_or_childcare_provider,
    },
  });

  function onSubmit(data: ChildCareSelectionFormData) {
    if (open === false && data.is_parent_or_childcare_provider === true) {
      setOpen(true);
      return;
    }
    updateDataCallback(data, data.is_parent_or_childcare_provider);
    if (data.is_parent_or_childcare_provider === false) nextStep();
  }

  const onChange = (callback: (data: boolean) => void) => (data: string) => {
    const value = data === 'true';
    callback(value);
    const formData = form.getValues();
    updateDataCallback(formData);
  };

  return (
    <>
      <div className="flex w-full justify-center">
        <div className={cn('flex flex-col gap-8', className)}>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
              <FormField
                control={form.control}
                name="is_parent_or_childcare_provider"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('childCare.question')}</FormLabel>
                    <FormControl>
                      <RadioGroup
                        defaultValue={`${field.value}`}
                        onValueChange={onChange(field.onChange)}
                        className="flex flex-col space-y-1"
                      >
                        <FormItem className="flex items-center space-x-3 space-y-0">
                          <FormControl>
                            <RadioGroupItem value="true" />
                          </FormControl>
                          <FormLabel className="font-normal">
                            {tShared('yes')}
                          </FormLabel>
                        </FormItem>
                        <FormItem className="flex items-center space-x-3 space-y-0">
                          <FormControl>
                            <RadioGroupItem value="false" />
                          </FormControl>
                          <FormLabel className="font-normal">
                            {tShared('no')}
                          </FormLabel>
                        </FormItem>
                      </RadioGroup>
                    </FormControl>
                    <FormMessage translationFunction={tError} />
                  </FormItem>
                )}
              />
              <SurveyFormActions
                type="submit"
                disableActions={disableActions}
              />
              <AlertDialog open={open} onOpenChange={setOpen}>
                {/* hide second dialog's trigger */}
                <AlertDialogTrigger style={{ display: 'none' }} />

                <AlertDialogContent>
                  <AlertDialogHeader>
                    <AlertDialogTitle>{t('childCare.sure')}</AlertDialogTitle>
                    <AlertDialogDescription>
                      {t('childCare.description')}
                    </AlertDialogDescription>
                  </AlertDialogHeader>
                  <AlertDialogFooter>
                    <AlertDialogCancel onClick={() => setOpen(false)}>
                      {tShared('no')}
                    </AlertDialogCancel>
                    <AlertDialogAction
                      onClick={() => onSubmit(form.getValues())}
                    >
                      {tShared('yes')}
                    </AlertDialogAction>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>
            </form>
          </Form>
        </div>
      </div>
    </>
  );
};
