import {
  queryOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import client, { APIResponse } from './client';
import { Language, UserProfile } from '../models';
import { UsersResponse } from './users';

export type UserProfileResponse = {
  user: UserProfile;
};

export const userProfileQueryOptions = queryOptions({
  queryKey: ['profile'],
  queryFn: () => fetchUserProfile(),
});

async function fetchUserProfile(): Promise<UserProfileResponse> {
  const response =
    await client.get<APIResponse<UserProfileResponse>>('/users/me');
  return response.data.data;
}

type DeleteUserResponse = {
  message: string;
  code: string;
};

type IdUser = { id: number };
type EmailUser = { email: string };

export type DeleteUserRequest = IdUser | EmailUser;

export type UpdateProfileRequest = {
  firstName: string;
  lastName: string;
};

export type UpdatePreferencesRequest = {
  preferredLanguage: Language;
};

export function isIdUser(value: Partial<IdUser & EmailUser>): value is IdUser {
  if (value == null) {
    return false;
  }
  return !value.email && !!value.id;
}

async function deleteUser(
  user: DeleteUserRequest,
): Promise<DeleteUserResponse> {
  let result;
  if (isIdUser(user)) {
    result = await client.delete<DeleteUserResponse>(`/users/${user.id}`);
  } else {
    result = await client.delete<DeleteUserResponse>(
      `/users/invite/${user.email}`,
    );
  }
  return result.data;
}

async function updateCurrentProfile(profile: UpdateProfileRequest) {
  return await client.put<void>('/users/me/profile', {
    first_name: profile.firstName,
    last_name: profile.lastName,
  });
}

async function updateCurrentPreferences(request: UpdatePreferencesRequest) {
  return await client.put<void>('/users/me/preferences', {
    preferred_language: request.preferredLanguage,
  });
}

export function useDeleteUser() {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: deleteUser,
    throwOnError: false,
    onMutate: async (deleteUser) => {
      await queryClient.cancelQueries({ queryKey: ['users'] });
      const previousUsers = queryClient.getQueryData(['users']);

      queryClient.setQueryData(['users'], (old: UsersResponse) => {
        let {
          users: { invited, registered },
        } = old;
        if (isIdUser(deleteUser)) {
          registered = old.users.registered.filter(
            (user) => user.id !== deleteUser.id,
          );
        } else {
          invited = old.users.invited.filter(
            (user) => user.email !== deleteUser.email,
          );
        }

        return { users: { invited, registered } };
      });

      return { previousUsers };
    },

    onError: (_, __, context) => {
      queryClient.setQueryData(['users'], context!.previousUsers);
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
    },
  });

  return {
    deleteUser: mutation.mutate,
    deleteUserAsync: mutation.mutateAsync,
    isPending: mutation.isPending,
    isSuccess: mutation.isSuccess,
  };
}

export function useUpdateCurrentProfile() {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: updateCurrentProfile,
    throwOnError: false,
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['profile'] });
    },
  });

  return mutation;
}

export function useUpdateCurrentPreferences() {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: updateCurrentPreferences,
    throwOnError: false,
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['profile'] });
    },
  });

  return mutation;
}
