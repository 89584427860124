import { WithClassName } from '@/types/with-classname';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/card';
import { Skeleton } from '../ui/skeleton';
import { LucideIcon } from 'lucide-react';
import { cn } from '@/lib/utils';

export interface CardWithTextLayoutProps extends WithClassName {
  title: string;
  description: string;
  subDescription?: string;
  icon?: LucideIcon;
  action?: () => void;
}

export const CardWithTextLayout = (props: CardWithTextLayoutProps) => {
  return (
    <Card
      onClick={props.action}
      className={cn(
        'flex flex-col max-w-[300px] w-full desktop:w-[300px] shadow-md hover:shadow-xl overflow-hidden transition-shadow duration-300',
        props.action && 'hover:cursor-pointer',
        props.className,
      )}
    >
      <CardHeader className="flex flex-row items-center justify-between space-y-0 px-6 py-3">
        <CardTitle className="text-sm font-medium flex justify-between w-full">
          <p>{props.title}</p>
          {props.icon && <props.icon className="h-5 w-5" />}
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="text-2xl font-bold">{props.description}</div>
        {props.subDescription && (
          <p className="text-xs text-muted-foreground">
            {props.subDescription}
          </p>
        )}
      </CardContent>
    </Card>
  );
};

export const CardWithTextLayoutSkeleton = () => (
  <Skeleton className="w-11/12 max-w-[300px] desktop:w-[300px] aspect-video" />
);
