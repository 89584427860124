import {
  CardWithTextLayoutSkeleton,
  CardWithTextLayout,
} from '@/components/layouts/card-with-text-layout';
import { DefaultItemsLayout } from '@/components/layouts/default-items-layout';
import { PageWithTitleLayout } from '@/components/layouts/page-with-title-layout';
import { useAuth } from '@/components/providers/auth';
import { coursesQueryOptions } from '@/lib/queries/course';
import { useSuspenseQuery } from '@tanstack/react-query';
import { createFileRoute, redirect } from '@tanstack/react-router';
import { BookMarked } from 'lucide-react';
import { useTranslation } from 'react-i18next';

export const Route = createFileRoute('/')({
  component: Index,
  beforeLoad: ({ context: { auth } }) => {
    if (!auth.isSignedIn) {
      throw redirect({
        to: '/auth',
        from: '/',
      });
    } else if (auth.requiresOnboarding) {
      throw redirect({
        to: '/survey',
        from: '/',
      });
    }
  },
  loader: ({ context: { queryClient } }) =>
    queryClient.ensureQueryData(coursesQueryOptions),
  pendingComponent: IndexSkeleton,
});

function Index() {
  const { user, isSignedIn } = useAuth();
  const navigate = Route.useNavigate();

  const { t } = useTranslation('translation', { keyPrefix: 'home' });

  const {
    data: { courses },
  } = useSuspenseQuery(coursesQueryOptions);

  const onClick = () => {
    navigate({
      from: '/',
      to: '/courses',
    });
  };

  return (
    <div className="p-2 flex flex-col gap-4">
      <PageWithTitleLayout
        title={`${t('welcome')} ${isSignedIn ? user?.displayName || '' : ''}`}
      >
        <DefaultItemsLayout>
          <CardWithTextLayout
            title={t('enroll')}
            description={courses.length.toString()}
            icon={BookMarked}
            subDescription={t('userEnroll')}
            action={onClick}
          />
        </DefaultItemsLayout>
      </PageWithTitleLayout>
    </div>
  );
}

function IndexSkeleton() {
  const { t } = useTranslation('translation', { keyPrefix: 'home' });

  return (
    <div className="p-2 flex flex-col gap-4">
      <PageWithTitleLayout title={t('welcome')}>
        <DefaultItemsLayout>
          <CardWithTextLayoutSkeleton />
        </DefaultItemsLayout>
      </PageWithTitleLayout>
    </div>
  );
}
