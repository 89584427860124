import { cn } from '@/lib/utils';
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from '../chart';
import { Bar, BarChart, Label, XAxis, YAxis } from 'recharts';
import { WithClassName } from '@/types/with-classname';

export type StackedBarChartDataType = {
  [key: string]: number;
};

export type StackedBarChartData = {
  name: string;
  data: StackedBarChartDataType;
};

interface StackedBarChartComponentProps {
  data: StackedBarChartData[];
  xLabel?: string;
  yLabel?: string;
  config: ChartConfig;
}

export function StackedBarChartComponent({
  data,
  xLabel,
  yLabel,
  config,
  className,
}: StackedBarChartComponentProps & WithClassName) {
  return (
    <ChartContainer config={config} className={cn('w-full', className)}>
      <BarChart accessibilityLayer data={data} margin={{ bottom: 15 }}>
        <XAxis
          dataKey="name"
          stroke="#888888"
          fontSize={12}
          tickLine={false}
          axisLine={false}
          className=""
        >
          {xLabel && (
            <Label fontSize={12} dy={25}>
              {xLabel}
            </Label>
          )}
        </XAxis>
        <YAxis stroke="#888888" fontSize={12} tickLine={false} axisLine={false}>
          {yLabel && (
            <Label dx={-15} fontSize={12} angle={-90}>
              {yLabel}
            </Label>
          )}
        </YAxis>
        {Object.keys(config).map((key, index) => {
          const configLength = Object.keys(config).length - 1;
          const radius: [number, number, number, number] =
            index === configLength
              ? [4, 4, 0, 0]
              : index === 0
                ? [0, 0, 4, 4]
                : [0, 0, 0, 0];
          return (
            <Bar
              stackId="a"
              key={key}
              dataKey={key}
              fill={config[key].color}
              radius={radius}
            />
          );
        })}
        <ChartTooltip
          cursor={false}
          content={<ChartTooltipContent className="min-w-40" />}
        />
      </BarChart>
    </ChartContainer>
  );
}

export const BarChartComponentSkeleton = () => {
  return <BarChartComponentSkeleton />;
};
