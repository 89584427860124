import {
  ColumnFiltersState,
  SortingState,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { userColumns } from './user-columns';
import { useTranslation } from 'react-i18next';

import { UsersDialogContent } from './users-dialog/users-dialog';
import { Dialog, DialogContent, DialogTrigger } from '../ui/dialog';
import {
  UserTableTopActions,
  UserTableTopActionsSkeleton,
} from './user-table-actions';
import { toast } from '../ui/use-toast';
import {
  BaseUser,
  useEditUserRoles,
  useInviteUsers,
} from '@/lib/queries/users';
import { InvitedUser, MappedUser, User } from '@/lib/models';
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogTrigger,
} from '../ui/alert-dialog';
import { DeleteUsersDialog } from './delete-users/delete-users-dialog';
import { useDeleteUser } from '@/lib/queries/user';
import {
  TableComponent,
  TableComponentSkeleton,
} from '../table/table-component';

export interface UsersTableProps {
  users: {
    registered: User[];
    invited: InvitedUser[];
  };
}

export const UsersTable = ({ users }: UsersTableProps) => {
  const [rowSelection, setRowSelection] = useState({});
  const [sorting, setSorting] = useState<SortingState>([
    {
      desc: false,
      id: 'email',
    },
  ]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [openUsersDialog, setOpenUsersDialog] = useState(false);
  const [usersToEdit, setUsersToEdit] = useState<BaseUser[] | null>(null);

  const [openDelete, setOpenDelete] = useState(false);

  const { t } = useTranslation('translation', { keyPrefix: 'users' });
  const { t: tError } = useTranslation('translation', { keyPrefix: 'error' });
  const { t: tShared } = useTranslation('translation', { keyPrefix: 'shared' });

  const { invite } = useInviteUsers();
  const { editUserAsync } = useEditUserRoles();
  const { deleteUserAsync } = useDeleteUser();

  const allUsersMapped = useMemo(() => {
    const accepted: MappedUser[] = users.registered.map((registeredUser) => ({
      ...registeredUser,
      status: 'accepted',
    }));

    const pending: MappedUser[] = users.invited.map((invitedUser) => ({
      ...invitedUser,
      status: 'pending',
    }));

    return accepted.concat(...pending);
  }, [users]);

  const openEdit = (selectedUsers?: MappedUser[]) => {
    let users: MappedUser[];

    if (!selectedUsers) {
      const rowsToGet = Object.keys(rowSelection);
      const rows = rowsToGet.map((id) => table.getRow(id));

      users = rows
        .map((row) => row.original)
        .filter((user) => user.status === 'accepted');
    } else {
      users = selectedUsers;
    }

    setOpenUsersDialog(true);

    setUsersToEdit(users as BaseUser[]);
  };

  const table = useReactTable<MappedUser>({
    data: allUsersMapped,
    columns: userColumns(openEdit),
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      rowSelection,
    },
    autoResetPageIndex: false,
  });

  const onCheckFilterChange = (key: string, value: string) => {
    if (table.getColumn(key)?.getFilterValue() === value) {
      table.getColumn(key)?.setFilterValue('');
      return;
    }
    table.getColumn(key)?.setFilterValue(value);
  };

  const onInputFilterChange = (key: string, value: string) => {
    table.getColumn(key)?.setFilterValue(value);
  };

  const getFilterValue = (key: string): string => {
    return `${table.getColumn(key)?.getFilterValue() ?? ''}`;
  };

  const onUsersSubmit = async (users: BaseUser[]) => {
    setOpenUsersDialog(false);

    if (!usersToEdit) {
      invite(users, {
        onSuccess: async () => {
          toast({
            title: tShared('success'),
            description: t('newUsers.successMessage'),
          });
        },
        onError: (err) => {
          toast({
            title: tError('type.submitError'),
            description: tError(err.message ?? 'message.unknown'),
          });
        },
      });
    } else {
      await editUserAsync(users);

      toast({
        title: tShared('success'),
        description: t('editUsersRole.successMessage'),
      });

      table.resetRowSelection();
    }

    setUsersToEdit(null);
  };

  const onNewUsersCancel = () => {
    setOpenUsersDialog(false);
    setUsersToEdit(null);
  };

  const openAdd = () => {
    setOpenUsersDialog(true);
    setUsersToEdit(null);
  };

  const onDeleteConfirm = async () => {
    const rowsToGet = Object.keys(rowSelection);
    const rows = rowsToGet.map((id) => table.getRow(id));
    await Promise.all(
      rows.map((row) => {
        const user = row.original;
        let requestUser;
        if (user.status === 'pending') {
          requestUser = { email: user.email! };
        } else {
          requestUser = { id: user.id! };
        }
        return deleteUserAsync(requestUser);
      }),
    );

    toast({
      title: tShared('success'),
      description: t('deleteUsers.response.message'),
    });

    table.resetRowSelection();
  };

  const onDeleteCancel = () => {
    setOpenDelete(false);
  };

  const selectionLength = useMemo(() => {
    return Object.keys(rowSelection).length;
  }, [rowSelection]);

  const acceptedSelectionLength = useMemo(() => {
    const rowsToGet = Object.keys(rowSelection);
    const rows = rowsToGet
      .map((id) => table.getRow(id))
      .map((row) => row.original)
      .filter((user) => user.status === 'accepted');

    return rows.length;
  }, [rowSelection, table]);

  return (
    <div className="w-full">
      <div className="flex items-center py-4 gap-2 flex-wrap">
        <UserTableTopActions
          onInputFilterChange={onInputFilterChange}
          getFilterValue={getFilterValue}
          onFilterChange={onCheckFilterChange}
          onResetClick={table.resetColumnFilters}
          onAddClick={openAdd}
          onEditClick={openEdit}
          onDeleteClick={() => setOpenDelete(true)}
          deleteDisabled={selectionLength < 1}
          editDisabled={acceptedSelectionLength < 1}
        />
      </div>
      <TableComponent table={table} />
      <Dialog open={openUsersDialog} onOpenChange={setOpenUsersDialog}>
        {/* hide second dialog's trigger */}
        <DialogTrigger style={{ display: 'none' }} />

        <DialogContent className="w-[90vw] desktop:max-w-3xl">
          <UsersDialogContent
            initialUsers={usersToEdit}
            onCancel={onNewUsersCancel}
            onSubmit={onUsersSubmit}
          />
        </DialogContent>
      </Dialog>
      <AlertDialog open={openDelete} onOpenChange={setOpenDelete}>
        {/* hide second dialog's trigger */}
        <AlertDialogTrigger style={{ display: 'none' }} />

        <AlertDialogContent>
          <DeleteUsersDialog
            onCancel={onDeleteCancel}
            onConfirm={onDeleteConfirm}
            selectedNumber={selectionLength}
          />
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};

export const UsersTableSkeleton = () => {
  return (
    <div className="w-full">
      <div className="flex items-center py-4 gap-2 flex-wrap">
        <UserTableTopActionsSkeleton />
      </div>
      <TableComponentSkeleton />
    </div>
  );
};
