import { queryOptions } from '@tanstack/react-query';
import api, { APIResponse } from './client';
import { Module } from '../models';

export type ModuleResponse = {
  module: Module;
};

export function moduleQueryOptions(courseId: string, moduleId: string) {
  return queryOptions({
    queryKey: ['module', courseId, moduleId],
    queryFn: () => fetchModuleById(courseId, moduleId),
  });
}

async function fetchModuleById(
  courseId: string,
  moduleId: string,
): Promise<ModuleResponse> {
  return api
    .get<
      APIResponse<ModuleResponse>
    >(`/courses/${courseId}/modules/${moduleId}`)
    .then((response) => response.data.data);
}

export type ModulesResponse = {
  modules: Module[];
};

export function modulesQueryOptions(courseId: string) {
  return queryOptions({
    queryKey: ['modules', courseId],
    queryFn: () => fetchModulesByCourseId(courseId),
  });
}

async function fetchModulesByCourseId(
  courseId: string,
): Promise<ModulesResponse> {
  return api
    .get<APIResponse<ModulesResponse>>(`/courses/${courseId}/modules`)
    .then((response) => response.data.data);
}
