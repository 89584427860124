import { useTranslation } from 'react-i18next';
import { Button, ButtonProps } from '../ui/button';
import { useStepper } from '../ui/stepper';

export type SurveyFormActionsProps = ButtonProps & { disableActions?: boolean };

export const SurveyFormActions = ({
  disableActions,
  ...props
}: SurveyFormActionsProps) => {
  const { t: tShared } = useTranslation('translation', { keyPrefix: 'shared' });

  const {
    prevStep,
    resetSteps,
    isDisabledStep,
    hasCompletedAllSteps,
    isLastStep,
    isOptionalStep,
  } = useStepper();

  return (
    <div className="w-full flex justify-center gap-8">
      {hasCompletedAllSteps ? (
        <Button size="sm" onClick={resetSteps}>
          {tShared('reset')}
        </Button>
      ) : (
        <>
          <Button
            disabled={isDisabledStep || disableActions}
            onClick={prevStep}
            size="sm"
            variant="secondary"
            type="button"
          >
            {tShared('prev')}
          </Button>
          <Button size="sm" {...props} disabled={disableActions}>
            {isLastStep
              ? tShared('finish')
              : isOptionalStep
                ? tShared('skip')
                : tShared('next')}
          </Button>
        </>
      )}
    </div>
  );
};
