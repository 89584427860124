import { cn } from '@/lib/utils';
import { WithClassName } from '@/types/with-classname';
import { PropsWithChildren } from 'react';

type DefaultItemsLayoutProps = PropsWithChildren &
  WithClassName & {
    noWrap?: boolean;
  };

export function DefaultItemsLayout({
  children,
  noWrap,
  className,
}: DefaultItemsLayoutProps) {
  return (
    <div
      className={cn(
        'flex flex-row flex-wrap gap-8',
        !noWrap ? 'mobile:justify-center' : '',
        className,
      )}
    >
      {children}
    </div>
  );
}
