import { UserFully } from '@/lib/queries/analytics/analytics-users';
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { usersAnalyticsColumns } from './users-analytics-table-columns';
import { useState } from 'react';
import { TableComponent } from '@/components/table/table-component';
import { useTranslation } from 'react-i18next';

interface UsersAnalyticsTableProps {
  users: UserFully[];
}

export const UsersAnalyticsTable = ({ users }: UsersAnalyticsTableProps) => {
  const { t: tShared } = useTranslation('translation', { keyPrefix: 'shared' });

  const [sorting, setSorting] = useState<SortingState>([
    {
      desc: false,
      id: 'email',
    },
  ]);

  const table = useReactTable<UserFully>({
    data: users,
    columns: usersAnalyticsColumns,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
    },
    autoResetPageIndex: false,
  });

  const onInputFilterChange = (key: string, value: string) => {
    table.getColumn(key)?.setFilterValue(value);
  };

  const getFilterValue = (key: string): string => {
    return `${table.getColumn(key)?.getFilterValue() ?? ''}`;
  };

  return (
    <TableComponent
      table={table}
      search={{
        onChange: (event) => onInputFilterChange('email', event.target.value),
        placeholder: tShared('email'),
        key: 'email',
        getFilterValue: getFilterValue,
      }}
    />
  );
};
