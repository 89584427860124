import { queryOptions, useMutation } from '@tanstack/react-query';
import client, { APIResponse } from './client';
import { OnboardingConfig, Residence } from '../models';

export type OnboardingConfigResponse = {
  config: OnboardingConfig;
};

export const onboardingConfigQueryOptions = queryOptions({
  queryKey: ['onboarding'],
  queryFn: () => fetchOnboardingConfig(),
});

async function fetchOnboardingConfig(): Promise<OnboardingConfigResponse> {
  const response =
    await client.get<APIResponse<OnboardingConfigResponse>>('/onboarding');
  return response.data.data;
}

export type UserOnboardingData = {
  preferred_language?: string;
  country?: string;
  state?: string;
  city?: string;
  is_parent_or_childcare_provider?: boolean;
  first_name?: string;
  last_name?: string;
  age_range?: string;
  gender?: string;
  profession?: string;
  profession_specialty?: string;
  custom_profession?: string;
  custom_profession_specialty?: string;
  primary_work_environment?: string;
  custom_primary_work_environment?: string;
};

export type UserOnboardingRequest = {
  preferred_language: string;
  primary_place_of_residence: Residence;
  is_parent_or_childcare_provider: boolean;
  first_name: string | null;
  last_name: string | null;
  age_range: string | null;
  gender: string | null;
  profession: string | null;
  custom_profession: string | null;
  profession_specialty: string | null;
  custom_profession_specialty: string | null;
  primary_work_environment: string | null;
  custom_primary_work_environment: string | null;
};

type UserOnboardingResponse = {
  message: string;
  code: string;
};

async function onboardUser(
  request: UserOnboardingRequest,
): Promise<UserOnboardingResponse> {
  const result = await client.post<UserOnboardingResponse>(
    '/onboarding/me',
    request,
  );
  return result.data;
}

export function useUserOnboarding() {
  const mutation = useMutation({
    mutationFn: onboardUser,
    throwOnError: false,
    mutationKey: ['profile'], // changes profile information
  });

  return {
    onboard: mutation.mutate,
    isPending: mutation.isPending,
    isSuccess: mutation.isSuccess,
  };
}
