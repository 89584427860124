import { useTranslation } from 'react-i18next';
import { AuthTabs } from './auth.tabs';
import ResetPasswordCard from './reset/reset.card';
import { Button } from '../ui/button';
import { Link } from '@tanstack/react-router';

export const Auth = () => {
  return (
    <div className="desktop:w-[100vw] desktop:h-[100vh] desktop:bg-auth-image desktop:bg-no-repeat desktop:bg-cover">
      <div className="desktop:w-full desktop:h-full desktop:bg-gradient-to-l desktop:from-30% desktop:from-transparent desktop:to-50% desktop:xl:to-60% desktop:to-white desktop:hover:bg-inherit">
        <div className="desktop:w-1/2 desktop:h-full desktop:justify-center desktop:items-center desktop:flex">
          <AuthTabs className="justify-center w-3/5 h-3/5 desktop:min-w-[350px] desktop:max-w-[500px] mobile:p-8 mobile:w-full" />
        </div>
      </div>
    </div>
  );
};

export const ForgotPassword = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'auth' });

  return (
    <>
      <h1 className="text-2xl font-semibold text-center">
        {t('forgotPassword')}
      </h1>
      <h2 className="text-lg text-center">{t('resetInstructionsHint')}</h2>
      <ResetPasswordCard className="w-3/5 h-3/5 desktop:min-w-[350px] desktop:max-w-[500px] mobile:p-8 mobile:w-full mt-4" />
    </>
  );
};

export const ForgotPasswordConfirmation = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'auth' });
  return (
    <>
      <h1 className="text-2xl font-semibold text-center">
        {t('resetLinkSent')}
      </h1>
      <h2 className="text-lg text-center">{t('resetLinkSentConfirmation')}</h2>
      <Link
        to="/auth"
        className="w-3/5 h-3/5 desktop:min-w-[350px] desktop:max-w-[500px] mobile:w-full mt-4"
      >
        <Button className="w-full" type="button">
          {t('backToLogin')}
        </Button>
      </Link>
    </>
  );
};
