import { ContentWithMedia, Media } from '@/lib/models';
import VideoMedia from './media/video-media';
import { Skeleton } from '../ui/skeleton';
import Markdown from 'react-markdown';

function MediaContent({ media }: { media: Media | null }) {
  if (!media) {
    throw new Error('Media content is missing');
  }

  switch (media.type) {
    case 'video':
      return <VideoMedia media={media} />;
    default:
      throw new Error(`Invalid media type: ${media.type}`);
  }
}

function TextContent({ content }: { content: ContentWithMedia }) {
  return <Markdown className="prose lg:prose-xl">{content.text}</Markdown>;
}

export function ChapterContent({ content }: { content: ContentWithMedia }) {
  switch (content.type) {
    case 'media':
      return <MediaContent media={content.media} />;
    case 'text':
      return <TextContent content={content} />;
    default:
      throw new Error(`Invalid content type: ${content.type}`);
  }
}

export default function ChapterContents({
  content,
}: {
  content: ContentWithMedia[];
}) {
  return (
    <div className="flex flex-col gap-4">
      {content.map((content) => (
        <ChapterContent key={content.id} content={content} />
      ))}
    </div>
  );
}

export const ChapterContentsSkeleton = () => {
  return <Skeleton className="min-w-80 w-full h-[25vh] desktop:h-[50vh]" />;
};
