import {
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { useTranslation } from 'react-i18next';

export interface DeleteUsersDialogProps {
  onCancel: () => void;
  onConfirm: () => void;
  selectedNumber: number;
}

export const DeleteUsersDialog = ({
  onCancel,
  onConfirm,
  selectedNumber,
}: DeleteUsersDialogProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'users' });
  const { t: tShared } = useTranslation('translation', { keyPrefix: 'shared' });

  const onCancelClick = () => {
    onCancel();
  };

  const onConfirmClick = () => {
    onConfirm();
  };

  return (
    <>
      <AlertDialogHeader>
        <AlertDialogTitle>{t('deleteUsers.sure')}</AlertDialogTitle>
        <AlertDialogDescription className="flex flex-col gap-2">
          <span>{t('deleteUsers.description')}</span>
          <span>{selectedNumber.toString() + t('deleteUsers.info')}</span>
        </AlertDialogDescription>
      </AlertDialogHeader>
      <AlertDialogFooter>
        <AlertDialogCancel onClick={onCancelClick}>
          {tShared('no')}
        </AlertDialogCancel>
        <AlertDialogAction onClick={onConfirmClick}>
          {tShared('yes')}
        </AlertDialogAction>
      </AlertDialogFooter>
    </>
  );
};
