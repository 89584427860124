import { cn } from '@/lib/utils';
import { buttonVariants } from '@/components/ui/button';

import { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '../ui/accordion';
import { ChevronDownIcon } from 'lucide-react';
import { Link, useMatches } from '@tanstack/react-router';
import { NavItem } from '@/types/nav-item';
import { useSidebar } from '../providers/sidebar';
import { useTranslation } from 'react-i18next';
import { WithClassName } from '@/types/with-classname';

type NavListProps = {
  items: NavItem[];
  setOpen?: (open: boolean) => void;
  disabled?: boolean;
} & WithClassName;

export function NavList({ items, setOpen, className, disabled }: NavListProps) {
  const matches = useMatches();
  const { t } = useTranslation('translation', { keyPrefix: 'navigation' });
  const { isOpen } = useSidebar();
  const [openItem, setOpenItem] = useState('');
  const [lastOpenItem, setLastOpenItem] = useState('');

  useEffect(() => {
    if (isOpen) {
      setOpenItem(lastOpenItem);
    } else {
      setLastOpenItem(openItem);
      setOpenItem('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const hasRouteMatch = (href: string) => {
    // Only match the root route if it's the only one
    if (href === '/') {
      return matches.filter((match) => match.pathname === href)?.length === 2;
    }
    return matches.some((match) => match.pathname === href);
  };

  return (
    <nav className="space-y-2">
      {items.map((item) =>
        item.children ? (
          <Accordion
            type="single"
            collapsible
            className="space-y-2"
            key={item.title}
            value={openItem}
            onValueChange={setOpenItem}
          >
            <AccordionItem value={item.title} className="border-none ">
              <AccordionTrigger
                className={cn(
                  buttonVariants({ variant: 'ghost' }),
                  'group relative flex h-12 justify-between px-4 py-2 text-base duration-200 hover:bg-muted hover:no-underline',
                )}
              >
                <div>
                  <item.icon className={cn('h-5 w-5', item.color)} />
                </div>
                <div
                  className={cn(
                    'absolute left-12 text-base duration-200 ',
                    !isOpen && className,
                  )}
                >
                  {t(item.title)}
                </div>

                {isOpen && (
                  <ChevronDownIcon className="h-4 w-4 shrink-0 text-muted-foreground transition-transform duration-200" />
                )}
              </AccordionTrigger>
              <AccordionContent className="mt-2 space-y-4 pb-1">
                {item.children?.map((child) => (
                  <Link
                    disabled={disabled}
                    key={child.title}
                    to={child.href}
                    onClick={() => {
                      if (setOpen) setOpen(false);
                    }}
                    className={cn(
                      buttonVariants({ variant: 'ghost' }),
                      'group relative flex h-12 justify-start gap-x-3',
                      hasRouteMatch(child.href) &&
                        'bg-muted font-bold hover:bg-muted',
                    )}
                  >
                    <child.icon className={cn('h-5 w-5', child.color)} />
                    <div
                      className={cn(
                        'absolute left-12 text-base duration-200',
                        !isOpen && className,
                      )}
                    >
                      {t(child.title)}
                    </div>
                  </Link>
                ))}
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        ) : (
          <Link
            disabled={disabled}
            key={item.title}
            to={item.href}
            onClick={() => {
              if (setOpen) setOpen(false);
            }}
            className={cn(
              buttonVariants({ variant: 'ghost' }),
              'group relative flex h-12 justify-start',
              hasRouteMatch(item.href) && 'bg-muted font-bold hover:bg-muted',
            )}
          >
            <item.icon className={cn('h-5 w-5', item.color)} />
            <span
              className={cn(
                'absolute left-12 text-base duration-200',
                !isOpen && className,
              )}
            >
              {t(item.title)}
            </span>
          </Link>
        ),
      )}
    </nav>
  );
}
