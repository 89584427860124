import { CoursesAnalyticsTable } from '@/components/analytics/table/courses-analytics-table';
import { TableComponentSkeleton } from '@/components/table/table-component';
import { AxiosErrorComponent } from '@/components/ui/errors/axios-error';
import { courseStatsFullyQueryOptions } from '@/lib/queries/analytics/analytics-course';
import { useSuspenseQuery } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute(
  '/analytics/_layout_table/courses/$courseId/table/$stat',
)({
  component: AnalyticsCoursesTable,
  loader: ({ context: { queryClient }, params }) =>
    queryClient.ensureQueryData(
      courseStatsFullyQueryOptions(params.courseId, params.stat),
    ),
  errorComponent: AxiosErrorComponent,
  pendingComponent: AnalyticsCoursesTableSkeleton,
});

function AnalyticsCoursesTable() {
  const { stat, courseId } = Route.useParams();

  const {
    data: { modules },
  } = useSuspenseQuery(courseStatsFullyQueryOptions(courseId, stat));

  return <CoursesAnalyticsTable modules={modules} />;
}

function AnalyticsCoursesTableSkeleton() {
  return <TableComponentSkeleton />;
}
