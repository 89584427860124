import { PropsWithChildren } from 'react';
import { AuthProvider } from './auth';
import QueryClientProvider from './query-client';
import { SidebarProvider } from './sidebar';

export default function Providers({ children }: PropsWithChildren) {
  return (
    <QueryClientProvider>
      <AuthProvider>
        <SidebarProvider>{children}</SidebarProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
}
