import { NavItem } from '@/types/nav-item';
import { Book, Home, Settings, TrendingUp, User2, Users } from 'lucide-react';

export const NavItems: NavItem[] = [
  {
    title: 'home',
    icon: Home,
    href: '/',
  },
  {
    title: 'courses',
    icon: Book,
    href: '/courses',
  },
  {
    title: 'analytics.base',
    icon: TrendingUp,
    href: '/analytics',
    admin: true,
  },
  {
    title: 'users',
    icon: Users,
    href: '/users',
    admin: true,
  },
];

export const AccountNavItems: NavItem[] = [
  {
    title: 'settings',
    icon: Settings,
    href: '/',
  },
  {
    title: 'myAccount',
    icon: User2,
    href: '/account',
  },
];
