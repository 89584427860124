import { createFileRoute, redirect, Outlet } from '@tanstack/react-router';

export const Route = createFileRoute('/analytics')({
  component: Analytics,
  pendingComponent: AnalyticsSkeleton,
  beforeLoad: ({ context: { auth } }) => {
    if (!auth.isAdmin) {
      throw redirect({
        to: '/',
        from: '/users',
      });
    }
  },
});

function Analytics() {
  return <Outlet />;
}

function AnalyticsSkeleton() {
  return <Outlet />;
}
