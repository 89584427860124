/* eslint-disable react-hooks/rules-of-hooks */
import { Button } from '@/components/ui/button';
import { CollapsibleButton } from '@/components/ui/collapsible-button';
import { ModuleFully } from '@/lib/queries/analytics/analytics-course';
import { cn } from '@/lib/utils';
import { ColumnDef } from '@tanstack/react-table';
import { ArrowUpDown } from 'lucide-react';
import { useTranslation } from 'react-i18next';

export const coursesAnalyticsColumns: ColumnDef<ModuleFully>[] = [
  {
    accessorKey: 'name',
    header: ({ column }) => {
      const { t } = useTranslation('translation', {
        keyPrefix: 'analytics.table.courses',
      });

      return (
        <Button
          variant="ghost"
          className="py-0 px-2"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          {t('name')}
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => (
      <div className="flex flex-row gap-2">
        {row.getCanExpand() && (
          <CollapsibleButton
            isExpanded={row.getIsExpanded()}
            onClick={row.getToggleExpandedHandler()}
          />
        )}
        <div
          className={cn(
            'flex justify-center items-center h-9',
            !row.getCanExpand() && 'ml-11',
          )}
        >
          {row.getValue('name')}
        </div>
      </div>
    ),
  },
  {
    accessorKey: 'progress',
    header: () => {
      const { t } = useTranslation('translation', {
        keyPrefix: 'analytics.table.courses',
      });

      return <div className="text-left">{t('averageProgress')}</div>;
    },
    cell: ({ row }) => (
      <div className="py-0 px-2">
        {(+row.getValue<number>('progress').toFixed(2) * 100).toFixed()}%
      </div>
    ),
  },
];
