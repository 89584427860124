import { AccountPreferences } from '@/components/account/account-preferences';
import { userProfileQueryOptions } from '@/lib/queries/user';
import { useSuspenseQuery } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/account/preferences')({
  component: PreferencesRoute,
  loader: ({ context: { queryClient } }) =>
    queryClient.ensureQueryData(userProfileQueryOptions),
});

function PreferencesRoute() {
  const {
    data: { user: profile },
  } = useSuspenseQuery(userProfileQueryOptions);

  return <AccountPreferences profile={profile} />;
}
