import { Card } from '@/components/ui/card';
import { cn } from '@/lib/utils';
import RegisterForm from './register.form';
import { WithClassName } from '@/types/with-classname';

export default function RegisterCard({ className }: WithClassName) {
  return (
    <Card
      className={cn(
        'p-4 shadow-md hover:shadow-xl overflow-hidden transition-shadow duration-300',
        className,
      )}
    >
      <RegisterForm />
    </Card>
  );
}
