import {
  RegistrationSurveySkeleton,
  RegistrationSurvey,
} from '@/components/registration-survey/registration-survey';
import { onboardingConfigQueryOptions } from '@/lib/queries/onboarding';
import { useSuspenseQuery } from '@tanstack/react-query';
import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/survey')({
  component: Survey,
  beforeLoad: ({ context }) => {
    if (!context.auth.isSignedIn) {
      throw redirect({
        to: '/auth',
        from: '/survey',
      });
    } else if (!context.auth.requiresOnboarding) {
      throw redirect({
        to: '/',
        from: '/survey',
      });
    }
  },
  loader: ({ context: { queryClient } }) =>
    queryClient.ensureQueryData(onboardingConfigQueryOptions),
  pendingComponent: SurveySkeleton,
});

function Survey() {
  const {
    data: { config },
  } = useSuspenseQuery(onboardingConfigQueryOptions);

  return <RegistrationSurvey config={config} />;
}

function SurveySkeleton() {
  return <RegistrationSurveySkeleton />;
}
