import { createFileRoute, Outlet, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/account/')({
  component: () => <Outlet />,
  beforeLoad: () => {
    throw redirect({
      to: '/account/profile',
      from: '/account',
    });
  },
});
