import { DialogDescription, DialogTitle } from '@radix-ui/react-dialog';
import { DialogFooter, DialogHeader } from '../../ui/dialog';
import { Button } from '../../ui/button';
import { useTranslation } from 'react-i18next';
import { CircleFadingPlus } from 'lucide-react';
import {
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  Table,
} from '../../ui/table';
import { useState } from 'react';
import React from 'react';
import { z } from 'zod';
import { useFieldArray, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Form } from '@/components/ui/form';
import { USER_ROLES, UserRole } from '@/lib/models';
import { BaseUser } from '@/lib/queries/users';
import { UsersDialogRow } from './users-dialog-row';

interface UsersDialogProps {
  onSubmit: (users: BaseUser[]) => void;
  onCancel: () => void;
  initialUsers: BaseUser[] | null;
}

const UsersDialogRowFormSchema = z
  .object({
    users: z.array(
      z.object({
        email: z
          .string()
          .min(1, { message: 'required' })
          .email({ message: 'invalid' }),
        role: z.enum(USER_ROLES),
        id: z.number().optional(),
      }),
    ),
  })
  .required();

export type UsersDialogRowFormData = z.infer<typeof UsersDialogRowFormSchema>;

export const UsersDialogContent = ({
  onCancel,
  onSubmit,
  initialUsers,
}: UsersDialogProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'users' });
  const { t: tUser } = useTranslation('translation', { keyPrefix: 'user' });

  const { t: tShared } = useTranslation('translation', { keyPrefix: 'shared' });
  const [users, setUsers] = useState<BaseUser[]>(
    initialUsers ?? [{ email: '', role: 'user' }],
  );

  const form = useForm<UsersDialogRowFormData>({
    resolver: zodResolver(UsersDialogRowFormSchema),
    defaultValues: {
      users: users.map((user) => ({
        id: user.id,
        email: user.email ?? '',
        role: user.role as UserRole,
      })),
    },
    shouldUnregister: false,
  });

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'users',
  });

  const removeElementAtIndex = (index: number) => {
    const auxUsers = [...users];
    auxUsers.splice(index, 1);
    setUsers(auxUsers);

    remove(index);
  };

  const onRoleChange = (index: number) => (role: UserRole) => {
    const usersCopy = [...users];
    usersCopy[index].role = role;
    setUsers(usersCopy);
  };

  const onEmailChange =
    (index: number) => (event: React.FormEvent<HTMLInputElement>) => {
      const auxUsers = [...users];
      auxUsers[index].email = event.currentTarget.value;
      setUsers(auxUsers);
    };

  const addAnotherUser = () => {
    setUsers([...users, { email: '', role: 'user' }]);
    append({ email: '', role: 'user' });
  };

  const onDialogConfirm = () => {
    onSubmit(form.getValues().users);
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onDialogConfirm)}>
        <DialogHeader>
          <DialogTitle>
            {t(`${!initialUsers ? 'newUsers' : 'editUsersRole'}.title`)}
          </DialogTitle>
          <DialogDescription>
            {t(`${!initialUsers ? 'newUsers' : 'editUsersRole'}.description`)}
          </DialogDescription>
        </DialogHeader>
        <div className="max-h-[40vh] desktop:max-h-[60vh] overflow-y-auto">
          <Table className="relative">
            <TableHeader>
              <TableRow>
                <TableHead>{tShared('email')}</TableHead>
                <TableHead>{tUser('role')}</TableHead>
                <TableHead></TableHead>
              </TableRow>
            </TableHeader>
            <TableBody className="[&_tr]:border-0">
              {fields.map((_, index) => (
                <UsersDialogRow
                  disableEmail={!!initialUsers}
                  key={index}
                  formControl={form.control}
                  onRoleChange={onRoleChange}
                  removeElementAtIndex={removeElementAtIndex}
                  onEmailChange={onEmailChange}
                  index={index}
                />
              ))}
            </TableBody>
          </Table>
        </div>
        {!initialUsers && (
          <div>
            <Button
              variant="ghost"
              className="gap-2"
              onClick={addAnotherUser}
              type="button"
            >
              <CircleFadingPlus /> {t('newUsers.add')}
            </Button>
          </div>
        )}
        <DialogFooter className="flex flex-row justify-end gap-2">
          <Button type="submit">{tShared('confirm')}</Button>
          <Button onClick={onCancel}>{tShared('cancel')}</Button>
        </DialogFooter>
      </form>
    </Form>
  );
};
