import { Link, useMatches } from '@tanstack/react-router';
import { useEffect, useState } from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from '../ui/breadcrumb';
import { cn } from '@/lib/utils';
import { CollapsedBreadcrumbNavigation } from './collapsed-breadcrumb-navigation';
import { BreadcrumbRouteDictionary } from '@/constants/crumbs';
import { useTranslation } from 'react-i18next';
import { WithClassName } from '@/types/with-classname';

export type BreadCrumbNavigationProps = {
  itemsToDisplay?: number;
} & WithClassName;

export interface Breadcrumb {
  route: string;
  params?: Record<string, string | number>;
  search?: Record<string, string | number>;
  label: string;
}

export default function BreadCrumbNavigation({
  itemsToDisplay,
  className,
}: BreadCrumbNavigationProps) {
  const ITEMS_TO_DISPLAY = itemsToDisplay ?? 3;

  const matches = useMatches();
  const { t } = useTranslation('translation', { keyPrefix: 'navigation' });

  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const computedBreadcrumbs = matches
      .filter((crumb) => crumb.routeId in BreadcrumbRouteDictionary)
      .map(
        (match): Breadcrumb => ({
          route:
            BreadcrumbRouteDictionary[match.routeId].overwriteRouteId ??
            match.routeId,
          search: match.search,
          params: match.params,
          label: t(BreadcrumbRouteDictionary[match.routeId].label),
        }),
      );

    setBreadcrumbs(computedBreadcrumbs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matches]);

  return (
    <Breadcrumb className={cn('flex flex-row sticky top-0', className)}>
      <BreadcrumbList>
        <BreadcrumbItem>
          <BreadcrumbLink asChild>
            <Link to={breadcrumbs[0]?.route} params={breadcrumbs[0]?.params}>
              {breadcrumbs[0]?.label}
            </Link>
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator />
        {breadcrumbs.length > ITEMS_TO_DISPLAY ? (
          <CollapsedBreadcrumbNavigation
            open={open}
            setOpen={setOpen}
            breadcrumbs={breadcrumbs}
          />
        ) : null}
        {breadcrumbs
          .slice(
            breadcrumbs.length > ITEMS_TO_DISPLAY ? -ITEMS_TO_DISPLAY + 1 : 1,
          )
          .map((crumb, index) => (
            <BreadcrumbItem key={index}>
              <BreadcrumbLink
                asChild
                className="max-w-20 truncate md:max-w-none"
              >
                <Link to={crumb.route} params={crumb.params}>
                  {crumb.label}
                </Link>
              </BreadcrumbLink>
              <BreadcrumbSeparator />
            </BreadcrumbItem>
          ))}
      </BreadcrumbList>
    </Breadcrumb>
  );
}
