import {
  UserOnboardingData,
  UserOnboardingRequest,
} from '@/lib/queries/onboarding';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const convertProperty = (property: any) => {
  if (typeof property === 'string' || property instanceof String) {
    return property === '' ? null : property ?? null;
  }
  return property ?? null;
};

export const mapDataToConfig = (values: UserOnboardingData) => {
  const mappedObject: UserOnboardingRequest = {
    preferred_language: convertProperty(values.preferred_language),
    primary_place_of_residence: {
      country: convertProperty(values.country),
      city: null,
      state: null,
    },
    is_parent_or_childcare_provider: convertProperty(
      values.is_parent_or_childcare_provider,
    ),
    first_name: null,
    last_name: null,
    age_range: null,
    gender: null,
    profession: null,
    profession_specialty: null,
    custom_profession: null,
    custom_profession_specialty: null,
    primary_work_environment: null,
    custom_primary_work_environment: null,
  };
  mappedObject.primary_place_of_residence.state = convertProperty(values.state);
  mappedObject.primary_place_of_residence.city = convertProperty(values.city);

  if (!values.is_parent_or_childcare_provider) {
    mappedObject.first_name = convertProperty(values.first_name);
    mappedObject.last_name = convertProperty(values.last_name);
    mappedObject.gender = convertProperty(values.gender);
    mappedObject.age_range = convertProperty(values.age_range);
    mappedObject.profession = convertProperty(values.profession);
    if (mappedObject.profession === 'other') {
      mappedObject.profession = null;
    }
    mappedObject.profession_specialty = convertProperty(
      values.profession_specialty,
    );
    if (mappedObject.profession_specialty === 'other') {
      mappedObject.profession_specialty = null;
    }
    mappedObject.custom_profession_specialty = convertProperty(
      values.custom_profession_specialty,
    );
    mappedObject.custom_profession = convertProperty(values.custom_profession);
    mappedObject.primary_work_environment = convertProperty(
      values.primary_work_environment,
    );
    if (mappedObject.primary_work_environment === 'other') {
      mappedObject.primary_work_environment = null;
      mappedObject.custom_primary_work_environment = convertProperty(
        values.custom_primary_work_environment,
      );
    }
  }

  return mappedObject;
};
