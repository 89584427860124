import { useTranslation } from 'react-i18next';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../ui/form';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select';
import { z } from 'zod';
import { SupportedLanguages, UserProfile } from '@/lib/models';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '../ui/button';
import { useUpdateCurrentPreferences } from '@/lib/queries/user';
import { useToast } from '../ui/use-toast';

export type AccountPreferencesProps = {
  profile: UserProfile;
};

const formSchema = z
  .object({
    preferredLanguage: z.enum(SupportedLanguages),
  })
  .required();

type AccountPreferencesFormData = z.infer<typeof formSchema>;

export function AccountPreferences({ profile }: AccountPreferencesProps) {
  const { t } = useTranslation();
  const { toast } = useToast();

  const updatePreferences = useUpdateCurrentPreferences();

  const form = useForm<AccountPreferencesFormData>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      preferredLanguage: profile.preferred_language,
    },
  });

  const onSubmit = (data: AccountPreferencesFormData) => {
    updatePreferences.mutate(data, {
      onSuccess: () =>
        toast({
          title: t('shared.success'),
          description: t('account.preferences.success'),
        }),
      onError: () =>
        toast({
          title: t('shared.error'),
          description: t('account.preferences.error'),
          variant: 'destructive',
        }),
    });
  };

  return (
    <Form {...form}>
      <form
        className="flex flex-col gap-4"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <FormField
          control={form.control}
          name="preferredLanguage"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('shared.language')}</FormLabel>
              <FormControl>
                <Select
                  value={field.value}
                  onValueChange={(v) => field.onChange(v)}
                >
                  <SelectTrigger>
                    <SelectValue placeholder={t('account.language.select')} />
                  </SelectTrigger>
                  <SelectContent>
                    {SupportedLanguages.map((language) => (
                      <SelectItem value={language} key={language}>
                        {t(`account.language.${language}`)}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </FormControl>
              <FormDescription>
                {t('account.language.description')}
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button loading={updatePreferences.isPending} type="submit">
          {t('account.preferences.update')}
        </Button>
      </form>
    </Form>
  );
}
