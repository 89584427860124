/* eslint-disable react-hooks/rules-of-hooks */
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { MappedUser } from '@/lib/models';
import { ColumnDef } from '@tanstack/react-table';
import { ArrowUpDown, MoreHorizontal } from 'lucide-react';
import { useTranslation } from 'react-i18next';

export const userColumns = (
  importCallback: (users: MappedUser[]) => void,
): ColumnDef<MappedUser>[] => [
  {
    id: 'select',
    header: ({ table }) => (
      <Checkbox
        checked={
          table.getIsAllPageRowsSelected() ||
          (table.getIsSomePageRowsSelected() && 'indeterminate')
        }
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'email',
    header: ({ column }) => {
      const { t } = useTranslation('translation', { keyPrefix: 'shared' });

      return (
        <Button
          variant="ghost"
          className="py-0 px-2"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          {t('email')}
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => (
      <div className="lowercase py-0 px-2">{row.getValue('email')}</div>
    ),
  },
  {
    accessorKey: 'status',
    header: () => {
      const { t } = useTranslation('translation', { keyPrefix: 'user' });

      return <div className="text-left">{t('status')}</div>;
    },
    cell: ({ row }) => (
      <div className="capitalize">{row.getValue('status')}</div>
    ),
  },
  {
    id: 'fullName',
    accessorFn: (user) => {
      return `${user.first_name ?? ''} ${user.last_name ?? ''}`;
    },
    header: ({ column }) => {
      const { t } = useTranslation('translation', { keyPrefix: 'shared' });

      return (
        <Button
          variant="ghost"
          className="py-0 px-2"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          {t('fullName')}
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => (
      <div className="py-0 px-2">{row.getValue('fullName')}</div>
    ),
  },
  {
    accessorKey: 'role',
    header: () => {
      const { t } = useTranslation('translation', { keyPrefix: 'user' });

      return <div className="text-right">{t('role')}</div>;
    },
    cell: ({ row }) => {
      return (
        <div className="text-right font-medium capitalize">
          {row.getValue('role')}
        </div>
      );
    },
  },
  {
    id: 'actions',
    enableHiding: false,
    cell: ({ row }) => {
      const { t } = useTranslation('translation', { keyPrefix: 'users' });

      const user = row.original;

      return (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" className="h-8 w-8 p-0">
              <span className="sr-only">{t('openActions')}</span>
              <MoreHorizontal className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuLabel>{t('actionsText')}</DropdownMenuLabel>
            <DropdownMenuItem
              onClick={() => navigator.clipboard.writeText(user.email!)}
            >
              {t('actions.copyEmail')}
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => importCallback([user])}>
              {t('actions.edit')}
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      );
    },
  },
];
