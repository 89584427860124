import {
  Bar,
  BarChart,
  Label,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import { Skeleton } from '../skeleton';
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from '../chart';

export interface BarChartData {
  name: string;
  total: number;
}

interface BarChartComponentProps {
  data: BarChartData[];
  xLabel?: string;
  yLabel?: string;
  config: ChartConfig;
}

export function BarChartComponent({
  data,
  xLabel,
  yLabel,
  config,
}: BarChartComponentProps) {
  return (
    <ChartContainer config={config}>
      <BarChart data={data} margin={{ bottom: 15 }}>
        <XAxis
          dataKey="name"
          stroke="#888888"
          fontSize={12}
          tickLine={false}
          axisLine={false}
        >
          {xLabel && (
            <Label fontSize={12} dy={25}>
              {xLabel}
            </Label>
          )}
        </XAxis>
        <YAxis stroke="#888888" fontSize={12} tickLine={false} axisLine={false}>
          {yLabel && (
            <Label dx={-15} fontSize={12} angle={-90}>
              {yLabel}
            </Label>
          )}
        </YAxis>
        <Bar
          dataKey="total"
          fill={config['total'].color}
          radius={[4, 4, 0, 0]}
        />
        <ChartTooltip
          cursor={false}
          content={<ChartTooltipContent className="min-w-40" />}
        />
      </BarChart>
    </ChartContainer>
  );
}

export const BarChartComponentSkeleton = () => {
  return (
    <ResponsiveContainer width="100%" height={350}>
      <Skeleton className="w-full h-full" />
    </ResponsiveContainer>
  );
};
