import { auth } from '@/lib/firebase';
import { UserProfile, UserRole } from '@/lib/models';
import { userProfileQueryOptions } from '@/lib/queries/user';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import firebase from 'firebase/auth';
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useToast } from '../ui/use-toast';

enum StorageKeys {
  User = 'user',
}

export type User = firebase.User & { role?: UserRole };
type OptionalUser = User | null;
type OptionalUserProfile = UserProfile | null;
export type UserWithProfile = User & {
  profile?: OptionalUserProfile;
};

export type OptionalUserWithProfile = UserWithProfile | null;

export type AuthState = {
  user: OptionalUserWithProfile;
  isSignedIn: boolean;
  signOut: () => void;
  requiresOnboarding: boolean;
  isAdmin: boolean;
};

export const AuthContext = createContext<AuthState>({
  user: null,
  isSignedIn: false,
  signOut: () => {},
  requiresOnboarding: false,
  isAdmin: false,
});

const useOptimisticAuth = (key: string) => {
  const dataJson = localStorage.getItem(key);
  if (dataJson) {
    const user = JSON.parse(dataJson);
    return user;
  }
  return null;
};

type Nullable<T> = T | null;

const setOptimisticAuth = <T,>(
  key: string,
  data: Nullable<T>,
  callback?: (data: Nullable<T>) => void,
) => {
  if (data) {
    localStorage.setItem(key, JSON.stringify(data));
    callback?.(data);
  } else {
    localStorage.removeItem(key);
    callback?.(null);
  }
};

const useUser = () => {
  const [user, setUser] = useState<OptionalUser>(
    useOptimisticAuth(StorageKeys.User),
  );

  const setUserOptimistic = (user: OptionalUser) => {
    setOptimisticAuth(StorageKeys.User, user, setUser);
  };

  return [user, setUserOptimistic] as const;
};

const useUserProfile = (enabled: boolean) => {
  const { i18n } = useTranslation();
  const { toast } = useToast();
  const { t: tError } = useTranslation('translation', { keyPrefix: 'error' });

  const {
    data: profile,
    isLoading,
    isFetching,
    isError,
    error,
    refetch,
  } = useQuery({
    ...userProfileQueryOptions,
    enabled,
  });

  const changeLanguage = useCallback(
    (data: OptionalUserProfile) => {
      i18n.changeLanguage(data?.preferred_language ?? 'en');
    },
    [i18n],
  );

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (isError) {
      toast({
        title: tError(error.name),
        description: tError(error.message ?? 'message.unknown'),
      });
      return;
    }
    changeLanguage(profile?.user ?? null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, isLoading, isError, error]);

  return {
    profile: profile?.user,
    refetch: refetch,
    isLoading: isLoading || isFetching,
  };
};

export function AuthProvider({ children }: PropsWithChildren) {
  const [user, setUser] = useUser();
  const { profile, refetch: refetchUserProfile } = useUserProfile(!!user);
  const queryClient = useQueryClient();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (!user) {
        setUser(null);
      } else {
        user.getIdTokenResult().then((token) => {
          const role = (token.claims['role'] ?? 'user') as UserRole;
          setUser({ ...user, role });
          refetchUserProfile();
        });
      }
    });

    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signOut = () => {
    auth.signOut();
    setUser(null);

    // Remove queries when the user changes.
    queryClient.removeQueries();
  };

  const isSignedIn = user != null;

  const requiresOnboarding =
    isSignedIn && profile != null && profile.onboarding_complete === false;

  return (
    <AuthContext.Provider
      value={{
        user: user ? { ...user, profile } : null,
        isSignedIn,
        requiresOnboarding,
        signOut,
        isAdmin: user?.role === 'admin',
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth(): AuthState {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}
