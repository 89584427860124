import { Outlet, createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/forgot-password')({
  component: ResetPasswordRoute,
});

function ResetPasswordRoute() {
  return (
    <div className="flex items-center flex-col gap-2 mt-10 p-4">
      <Outlet />
    </div>
  );
}
