// Import the functions you need from the SDKs you need
import { FirebaseError, initializeApp } from 'firebase/app';
import {
  GoogleAuthProvider,
  UserCredential,
  getAuth,
  getIdToken,
  onAuthStateChanged,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  User,
  sendPasswordResetEmail,
} from 'firebase/auth';
import { z } from 'zod';
import * as Sentry from '@sentry/react';

const firebaseConfig = {
  apiKey: 'AIzaSyDtdsJXpRAgKQhdtyAIt-FFzcDY8E5Wb1Y',
  authDomain: 'auth.carewaysconnect.com',
  projectId: 'amazing-aleph-416800',
  storageBucket: 'amazing-aleph-416800.appspot.com',
  messagingSenderId: '946673504157',
  appId: '1:946673504157:web:8444ea2d8d00b112b82974',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

async function signInWithEmailAndPass(
  email: string,
  password: string,
): Promise<UserCredential> {
  return signInWithEmailAndPassword(auth, email, password);
}

function signUpWithEmailAndPass(
  email: string,
  password: string,
): Promise<UserCredential> {
  return createUserWithEmailAndPassword(auth, email, password);
}

function signInWithGooglePopup(): Promise<UserCredential> {
  const provider = new GoogleAuthProvider();
  provider.setCustomParameters({ prompt: 'select_account' });
  return signInWithPopup(auth, provider);
}

function sendPasswordResetLink(email: string): Promise<void> {
  return sendPasswordResetEmail(auth, email);
}

function parseAuthError(error: unknown): string | null {
  if (error instanceof FirebaseError) {
    switch (error.code) {
      case 'auth/popup-closed-by-user':
        return null;
      case 'auth/cancelled-popup-request':
        return null;
      case 'auth/popup-blocked':
        return 'message.popup-blocked';
      case 'auth/invalid-credential':
        return 'message.invalid-credential';
      case 'auth/internal-error':
        return parseInternalAuthError(error);
      default:
        return null;
    }
  }

  return null;
}

const customAuthErrorSchema = z.object({
  status: z.string().min(1),
  message: z.string(),
  code: z.number(),
});

type CustomAuthError = z.infer<typeof customAuthErrorSchema>;

function parseInternalAuthError(error: FirebaseError): string | null {
  const customError = customAuthErrorFromMessage(error.message);
  if (!customError) return null;

  switch (customError.status) {
    case 'EMAIL_ALREADY_EXISTS':
      return 'message.email-already-exists';
    default:
      return null;
  }
}

function customAuthErrorFromMessage(message: string): CustomAuthError | null {
  const cloudFunctionPrefix =
    'Firebase: HTTP Cloud Function returned an error: ';
  if (!message.startsWith(cloudFunctionPrefix)) {
    return null;
  }
  const internalErrorSuffix = '(auth/internal-error).';
  if (!message.endsWith(internalErrorSuffix)) {
    return null;
  }
  message = message.replace(cloudFunctionPrefix, '');
  message = message.replace(internalErrorSuffix, '');
  try {
    const errorObject = customAuthErrorSchema.parse(JSON.parse(message));
    return errorObject;
  } catch (ex) {
    Sentry.captureException(ex);
    return null;
  }
}

function getUserToken() {
  return new Promise((resolve, _) => {
    const unsub = onAuthStateChanged(auth, async (user: User | null) => {
      if (user) {
        const token = await getIdToken(user);
        resolve(token);
      } else {
        resolve(null);
      }
      unsub();
    });
  });
}

export {
  app,
  auth,
  signInWithGooglePopup,
  parseAuthError,
  getUserToken,
  signInWithEmailAndPass,
  signUpWithEmailAndPass,
  sendPasswordResetLink,
};
