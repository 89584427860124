import { cn } from '@/lib/utils';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import {
  FormField,
  FormItem,
  FormControl,
  FormMessage,
  Form,
  FormLabel,
} from '../../ui/form';
import { ClearLabeledInput } from '../../ui/input';
import { FormDataProps } from '../registration-survey';
import { useStepper } from '../../ui/stepper';
import { SurveyFormActions } from '../form-actions';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../ui/select';

enum GenderEnum {
  Male = 'male',
  Female = 'female',
  Undisclosed = 'undisclosed',
}
const genderValues = ['male', 'female', 'undisclosed', 'other', ''] as const;
type GenderValues = (typeof genderValues)[number];
const ageRanges = ['18-25', '26-35', '36-45', '46-55', '>55', ''] as const;
type AgeRanges = (typeof ageRanges)[number];

const PersonalInformationFormSchema = z
  .object({
    first_name: z.string(),
    last_name: z.string(),
    age_range: z.enum(ageRanges),
    gender: z.enum(genderValues),
  })
  .partial();
type PersonalInformationFormData = z.infer<
  typeof PersonalInformationFormSchema
>;

export default function PersonalInformationForm({
  updateDataCallback,
  className,
  config,
  formData,
}: FormDataProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'survey' });
  const { t: tShared } = useTranslation('translation', { keyPrefix: 'shared' });
  const { t: tError } = useTranslation('translation', {
    keyPrefix: 'error.form',
  });

  const { nextStep } = useStepper();

  const form = useForm<PersonalInformationFormData>({
    resolver: zodResolver(PersonalInformationFormSchema),
    defaultValues: {
      first_name: formData.first_name ?? '',
      last_name: formData.last_name ?? '',
      age_range: (formData.age_range ?? '') as AgeRanges,
      gender: (formData.gender ?? '') as GenderValues,
    },
  });

  function onSubmit(data: PersonalInformationFormData) {
    updateDataCallback(data);
    nextStep();
  }

  const genderSelection = [
    ...Object.values(GenderEnum).map((value) => ({
      value: value,
      displayValue: t(`personal.gender.${value}`),
    })),
    { value: 'other', displayValue: tShared('other') },
  ];

  const onInputChange =
    (callback: (data: string) => void) =>
    (event: React.FormEvent<HTMLInputElement>) => {
      callback(event.currentTarget.value);
      const formData = form.getValues();
      updateDataCallback(formData);
    };

  const onSelectChange =
    (callback: (data: string) => void) => (data: string) => {
      callback(data);
      const formData = form.getValues();
      updateDataCallback(formData);
    };

  return (
    <div className="flex w-full justify-center">
      <div className={cn('flex flex-col gap-8', className)}>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
            <FormField
              control={form.control}
              name="first_name"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <ClearLabeledInput
                      autoComplete="given-name"
                      id="first_name"
                      placeholder={tShared('firstName')}
                      {...field}
                      onChange={onInputChange(field.onChange)}
                    />
                  </FormControl>
                  <FormMessage translationFunction={tError} />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="last_name"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <ClearLabeledInput
                      autoComplete="family-name"
                      id="last_name"
                      placeholder={tShared('lastName')}
                      {...field}
                      onChange={onInputChange(field.onChange)}
                    />
                  </FormControl>
                  <FormMessage translationFunction={tError} />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="age_range"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('personal.age.label')}</FormLabel>
                  <Select
                    onValueChange={onSelectChange(field.onChange)}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder={t('personal.age.select')} />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {config.age_ranges.map((ageInterval) => (
                        <SelectItem key={ageInterval} value={ageInterval}>
                          {ageInterval}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage translationFunction={tError} />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="gender"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('personal.gender.label')}</FormLabel>
                  <Select
                    onValueChange={onSelectChange(field.onChange)}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue
                          placeholder={t('personal.gender.select')}
                        />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {genderSelection.map((gender) => (
                        <SelectItem key={gender.value} value={gender.value}>
                          {gender.displayValue}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage translationFunction={tError} />
                </FormItem>
              )}
            />
            <SurveyFormActions type="submit" />
          </form>
        </Form>
      </div>
    </div>
  );
}
