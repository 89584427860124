import {
  queryOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import client, { APIResponse } from './client';
import { InvitedUser, User, UserRole } from '../models';

export type UsersResponse = {
  users: {
    registered: User[];
    invited: InvitedUser[];
  };
};

export const usersQueryOptions = queryOptions({
  queryKey: ['users'],
  queryFn: () => fetchUsers(),
});

async function fetchUsers(): Promise<UsersResponse> {
  const response = await client.get<APIResponse<UsersResponse>>('/users');
  return response.data.data;
}

export interface BaseUser {
  id?: number;
  email: string;
  role: UserRole;
}

export type NewUserRequest = BaseUser[];

type NewUserResponse = {
  message: string;
  code: string;
};

async function inviteUsers(request: NewUserRequest): Promise<NewUserResponse> {
  const result = await client.post<NewUserResponse>('/users/invite', request);
  return result.data;
}

export function useInviteUsers() {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: inviteUsers,
    throwOnError: false,
    onMutate: async (newUsers) => {
      await queryClient.cancelQueries({ queryKey: ['users'] });
      const previousUsers = queryClient.getQueryData(['users']);

      queryClient.setQueryData(['users'], (old: UsersResponse) => {
        const {
          users: { invited, registered },
        } = old;

        return { users: { invited: invited.concat(newUsers), registered } };
      });

      return { previousUsers };
    },

    onError: (_, __, context) => {
      queryClient.setQueryData(['users'], context!.previousUsers);
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
    },
  });

  return {
    invite: mutation.mutate,
    isPending: mutation.isPending,
    isSuccess: mutation.isSuccess,
  };
}

type EditUsersRolesResponse = {
  message: string;
  code: string;
};
export type EditUsersRolesRequest = BaseUser[];

async function editUsersRoles(
  users: EditUsersRolesRequest,
): Promise<EditUsersRolesResponse> {
  const result = await client.put<EditUsersRolesResponse>(
    `/users/batch_update`,
    users,
  );
  return result.data;
}

export function useEditUserRoles() {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: editUsersRoles,
    throwOnError: false,
    onMutate: async (editUsers) => {
      await queryClient.cancelQueries({ queryKey: ['users'] });
      const previousUsers = queryClient.getQueryData(['users']);

      queryClient.setQueryData(['users'], (old: UsersResponse) => {
        const {
          users: { invited, registered },
        } = old;

        const registeredOptimistic = registered.map((r) => {
          const editedUser = editUsers.find((edit) => edit.id === r.id);
          return {
            ...r,
            role: editedUser?.role || r.role,
          };
        });

        return { users: { invited, registered: registeredOptimistic } };
      });

      return { previousUsers };
    },

    onError: (_, __, context) => {
      queryClient.setQueryData(['users'], context!.previousUsers);
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
    },
  });

  return {
    editUser: mutation.mutate,
    editUserAsync: mutation.mutateAsync,
    isPending: mutation.isPending,
    isSuccess: mutation.isSuccess,
  };
}
