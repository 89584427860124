import { createFileRoute, Outlet } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

export const Route = createFileRoute('/analytics/_layout_table')({
  component: LayoutAnalytics,
  loader: ({ location }) => {
    return { tab: location.pathname.split('/').pop() };
  },
  pendingComponent: LayoutAnalyticsSkeleton,
});

function LayoutAnalytics() {
  const { t } = useTranslation('translation', { keyPrefix: 'analytics.table' });

  return (
    <div className="flex flex-col w-full gap-4">
      <span className="text-3xl font-bold">{t('title')}</span>

      <Outlet />
    </div>
  );
}

function LayoutAnalyticsSkeleton() {
  const { t } = useTranslation('translation', { keyPrefix: 'analytics' });

  return (
    <div className="flex flex-col w-full gap-4">
      <span className="text-3xl font-bold">{t('title')}</span>
      <Outlet />
    </div>
  );
}
