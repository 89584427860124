import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormDescription,
  FormMessage,
} from '../../ui/form';
import { useStepper } from '../../ui/stepper';
import { FormDataProps } from '../registration-survey';
import { useTranslation } from 'react-i18next';
import { TLanguageCode, languages } from 'countries-list';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '../../ui/select';
import { SurveyFormActions } from '../form-actions';
import { cn } from '@/lib/utils';

const LanguageSelectionFormSchema = z.object({
  preferred_language: z.string(),
});

type LanguageSelectionFormData = z.infer<typeof LanguageSelectionFormSchema>;

export const LanguageSelectionForm = ({
  updateDataCallback: submitCallback,
  className,
  config,
}: FormDataProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'survey' });
  const { t: tError } = useTranslation('translation', {
    keyPrefix: 'error.form',
  });
  const { i18n } = useTranslation();
  const { nextStep } = useStepper();

  const defaultLanguages: TLanguageCode[] =
    config.preferred_languages as TLanguageCode[];

  const form = useForm<LanguageSelectionFormData>({
    resolver: zodResolver(LanguageSelectionFormSchema),
    defaultValues: {
      preferred_language: i18n.language,
    },
  });

  function onSubmit(data: LanguageSelectionFormData) {
    submitCallback(data);
    nextStep();
  }

  const onValueChange =
    (callback: (data: string) => void) => (data: string) => {
      callback(data);
      i18n.changeLanguage(data);
    };

  return (
    <>
      <p
        className={cn(className, 'max-w-full self-center mobile:w-full w-9/12')}
      >
        {t('start')}
      </p>
      <div className="flex w-full justify-center">
        <div className={cn('flex flex-col gap-8', className)}>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
              <FormField
                control={form.control}
                name="preferred_language"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('language.label')}</FormLabel>
                    <Select
                      onValueChange={onValueChange(field.onChange)}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder={t('language.select')} />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectGroup>
                          <SelectLabel>{t('language.supported')}</SelectLabel>

                          {defaultLanguages.map((language) => (
                            <SelectItem key={language} value={language}>
                              {languages[language].native}
                            </SelectItem>
                          ))}
                        </SelectGroup>

                        <SelectGroup>
                          <SelectLabel>{t('language.unsupported')}</SelectLabel>

                          {Object.entries(languages)
                            .filter(
                              ([key]) =>
                                !defaultLanguages.includes(
                                  key as TLanguageCode,
                                ),
                            )
                            .map(([key, value]) => (
                              <SelectItem key={key} value={key}>
                                {value.native}
                              </SelectItem>
                            ))}
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                    <FormDescription>
                      {t('language.description')}
                    </FormDescription>
                    <FormMessage translationFunction={tError} />
                  </FormItem>
                )}
              />
              <SurveyFormActions type="submit" />
            </form>
          </Form>
        </div>
      </div>
    </>
  );
};
