import {
  CourseModule,
  CourseModuleSkeleton,
} from '@/components/course-module/course-module';
import { AxiosErrorComponent } from '@/components/ui/errors/axios-error';
import { Chapter } from '@/lib/models';
import {
  chapterQueryOptions,
  chaptersQueryOptions,
} from '@/lib/queries/chapter';
import { moduleQueryOptions } from '@/lib/queries/module';
import { useSuspenseQuery } from '@tanstack/react-query';
import { createFileRoute, redirect } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

export type CourseModuleChapterLoaderResult = {
  activeChapterId: string | undefined;
};

export interface ChapterSearch {
  chapter: number;
}

export const Route = createFileRoute('/courses/$courseId/modules/$moduleId')({
  beforeLoad: ({ context: { auth } }) => {
    if (!auth.isSignedIn) {
      throw redirect({
        to: '/auth',
        from: '/courses/$courseId/modules',
        search: {
          redirect: '/courses/$courseId/modules',
        },
      });
    }
  },
  validateSearch: (search: Record<string, unknown>): ChapterSearch => {
    return {
      chapter: Number(search?.chapter ?? 0),
    };
  },
  loaderDeps: ({ search: { chapter } }) => ({ chapterIndex: chapter }),
  loader: async ({
    context: { queryClient },
    params: { courseId, moduleId },
    deps: { chapterIndex },
  }): Promise<CourseModuleChapterLoaderResult> => {
    const modulePromise = queryClient.ensureQueryData(
      moduleQueryOptions(courseId, moduleId),
    );

    const chaptersPromise = queryClient.ensureQueryData(
      chaptersQueryOptions(courseId, moduleId),
    );

    const [_, { chapters }] = await Promise.all([
      modulePromise,
      chaptersPromise,
    ]);

    const selectedChapter = chapterFromChapterIndex(chapters, chapterIndex);
    if (!selectedChapter) {
      return {
        activeChapterId: undefined,
      };
    }

    await queryClient.ensureQueryData(
      chapterQueryOptions(courseId, moduleId, selectedChapter.id.toString()),
    );

    return {
      activeChapterId: selectedChapter.id.toString(),
    };
  },
  component: ModuleComponent,
  pendingComponent: CourseModuleSkeleton,
  errorComponent: AxiosErrorComponent,
});

function ModuleComponent() {
  const { t } = useTranslation('translation', { keyPrefix: 'module' });
  const { activeChapterId } = Route.useLoaderData();
  const { courseId, moduleId } = Route.useParams();

  const {
    data: { module },
  } = useSuspenseQuery(moduleQueryOptions(courseId, moduleId));

  const {
    data: { chapters },
  } = useSuspenseQuery(chaptersQueryOptions(courseId, moduleId));

  const {
    data: { chapter },
  } = useSuspenseQuery(
    chapterQueryOptions(courseId, moduleId, activeChapterId),
  );

  if (!activeChapterId) {
    return <div>{t('noChapters')}</div>;
  }

  return <CourseModule module={module} chapters={chapters} chapter={chapter} />;
}

function chapterFromChapterIndex(
  chapters: Chapter[],
  chapterIndex: number,
): Chapter | undefined {
  if (chapters.length === 0) {
    return undefined;
  }
  const index = Math.min(chapterIndex, chapters.length - 1);
  return chapters[index];
}
