import { ChevronUp, ChevronDown } from 'lucide-react';
import { Button } from './button';
import React from 'react';
import { WithClassName } from '@/types/with-classname';
import { cn } from '@/lib/utils';

interface CollapsibleButtonProps {
  isExpanded: boolean;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
}

export const CollapsibleButton = ({
  isExpanded,
  onClick,
  className,
}: CollapsibleButtonProps & WithClassName) => {
  const _icon = isExpanded ? ChevronUp : ChevronDown;
  return (
    <Button
      variant="ghost"
      size="sm"
      className={cn('w-9 p-0', className)}
      onClick={onClick}
    >
      <_icon className="h-4 w-4" />
      <span className="sr-only">Toggle</span>
    </Button>
  );
};
