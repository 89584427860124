import { AxiosError } from 'axios';

interface AxiosErrorComponentProps {
  error: unknown;
}

export const AxiosErrorComponent = ({ error }: AxiosErrorComponentProps) => {
  return (
    <div>
      <pre>{parseAxiosError(error)}</pre>
    </div>
  );
};

function parseAxiosError(error: unknown) {
  if (error instanceof AxiosError) {
    if (error.response?.data) {
      return JSON.stringify(error.response.data, null, 2);
    }
    return error.message;
  }
  return 'Unknown error';
}
