import { parseAuthError, signInWithGooglePopup } from '@/lib/firebase';
import { useNavigate } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import GoogleIcon from '@/assets/google-icon.svg?react';
import { Button } from '../../ui/button';
import { useToast } from '@/components/ui/use-toast';
import { WithClassName } from '@/types/with-classname';

export type GoogleFormLoginProps = {
  onSuccess?: () => void;
} & WithClassName;

export default function GoogleFormLogin({
  className,
  onSuccess,
}: GoogleFormLoginProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'auth' });
  const { t: tError } = useTranslation('translation', { keyPrefix: 'error' });

  const { toast } = useToast();
  const navigate = useNavigate({ from: '/auth' });

  const loginWithGoogle = async () => {
    try {
      await signInWithGooglePopup();
      onSuccess?.();
      navigate({ from: '/auth', to: '/' });
    } catch (error) {
      const message = parseAuthError(error);
      if (message) {
        toast({
          title: tError('type.loginError'),
          description: tError(message ?? 'message.unknown'),
        });
      }
    }
  };

  return (
    <div className={className}>
      <Button className="flex space-x-1 w-full" onClick={loginWithGoogle}>
        <GoogleIcon className="w-5 h-full" title="Google Logo" />
        <span>{t('loginWithGoogle')}</span>
      </Button>
    </div>
  );
}
