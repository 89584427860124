import { queryOptions } from '@tanstack/react-query';
import api from '../client';

const status = [
  'media_completed',
  'media_never_started',
  'media_started_not_completed',
] as const;
type statuses = (typeof status)[number];

export interface CourseStatProperties {
  media_completed: number;
  media_never_started: number;
  media_started_not_completed: number;
}

export interface CourseStatsByModule extends CourseStatProperties {
  course_id: number;
  course_name: string;
  module_description: string;
  module_id: number;
  module_name: string;
}

export interface CourseStats extends CourseStatProperties {
  course_id: number;
  course_name: string;
}

export type CourseStatsResponse = {
  stats: {
    byModule: CourseStatsByModule[];
    total: CourseStats[];
  };
};

export const courseStatsQueryOptions = queryOptions({
  queryKey: ['coursestats'],
  queryFn: () => fetchCourseStats(),
});

async function fetchCourseStats(): Promise<CourseStatsResponse> {
  return api.get(`/coursestats/summary`).then((response) => response.data.data);
}

export interface EntityFully {
  id: number;
  name: string;
  stat: statuses;
  chapters?: EntityFully[];
}

export type ModuleFully = EntityFully;

export interface CourseStatsFullyResponse {
  modules: ModuleFully[];
}

export function courseStatsFullyQueryOptions(courseId: string, stat: string) {
  return queryOptions({
    queryKey: ['coursestats', 'fully', courseId, stat],
    queryFn: () => fetchCourseStatsFully(courseId, stat),
  });
}

async function fetchCourseStatsFully(
  courseId: string,
  stat: string,
): Promise<CourseStatsFullyResponse> {
  return api
    .get(`/coursestats/${courseId}/fully`, { params: { stat } })
    .then((response) => response.data.data);
}
