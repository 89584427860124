/* eslint-disable react-hooks/rules-of-hooks */
import { Button } from '@/components/ui/button';
import { UserFully } from '@/lib/queries/analytics/analytics-users';
import { ColumnDef } from '@tanstack/react-table';
import { ArrowUpDown } from 'lucide-react';
import { useTranslation } from 'react-i18next';

export const usersAnalyticsColumns: ColumnDef<UserFully>[] = [
  {
    accessorKey: 'email',
    header: ({ column }) => {
      const { t } = useTranslation('translation', { keyPrefix: 'shared' });

      return (
        <Button
          variant="ghost"
          className="py-0 px-2"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          {t('email')}
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => (
      <div className="lowercase py-0 px-2">{row.getValue('email')}</div>
    ),
  },
  {
    id: 'fullName',
    accessorFn: (user) => {
      return `${user.first_name ?? ''} ${user.last_name ?? ''}`;
    },
    header: ({ column }) => {
      const { t } = useTranslation('translation', { keyPrefix: 'shared' });

      return (
        <Button
          variant="ghost"
          className="py-0 px-2"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          {t('fullName')}
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => (
      <div className="py-0 px-2">{row.getValue('fullName')}</div>
    ),
  },
  {
    accessorKey: 'progress',
    header: () => {
      const { t } = useTranslation('translation', {
        keyPrefix: 'analytics.table.users',
      });

      return <div className="text-left">{t('averageProgress')}</div>;
    },
    cell: ({ row }) => (
      <div className="py-0 px-2">
        {(+row.getValue<number>('progress').toFixed(2) * 100).toFixed()}%
      </div>
    ),
  },
];
