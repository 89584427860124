import {
  QueryClient,
  QueryClientProvider as RQProvider,
  QueryClientProviderProps as RQProviderProps,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
export type QueryClientProviderProps = Omit<RQProviderProps, 'client'>;

export const queryClient = new QueryClient();

const QueryClientProvider = ({ children }: QueryClientProviderProps) => {
  return (
    <RQProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      {children}
    </RQProvider>
  );
};

export default QueryClientProvider;
