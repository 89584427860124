import { Auth } from '@/components/auth/auth';
import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/auth')({
  component: Login,
  beforeLoad: ({ context }) => {
    if (context.auth.isSignedIn) {
      throw redirect({
        to: '/',
        from: '/auth',
      });
    }
  },
});

function Login() {
  return <Auth />;
}
