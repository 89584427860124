import { Button } from '@/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { cn } from '@/lib/utils';
import { useVirtualizer } from '@tanstack/react-virtual';
import { Check, ChevronsUpDown } from 'lucide-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Skeleton } from './skeleton';

type Option = {
  value: string;
  displayValue?: string;
};

interface VirtualizedCommandProps {
  height: string;
  options: Option[];
  placeholder: string;
  selectedOption: string;
  isLoading: boolean;
  onSelectOption?: (option: string) => void;
}

const VirtualizedCommand = ({
  height,
  options,
  placeholder,
  selectedOption,
  isLoading,
  onSelectOption,
}: VirtualizedCommandProps) => {
  const { t: tShared } = useTranslation('translation', { keyPrefix: 'shared' });

  const [filteredOptions, setFilteredOptions] =
    React.useState<Option[]>(options);
  const parentRef = React.useRef(null);

  const virtualizer = useVirtualizer({
    count: filteredOptions.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 35,
    overscan: 5,
  });

  const handleSearch = (search: string) => {
    setFilteredOptions(
      options.filter((option) =>
        option.value.toLowerCase().includes(search.toLowerCase() ?? []),
      ),
    );
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
      event.preventDefault();
    }
  };

  return (
    <Command shouldFilter={false} onKeyDown={handleKeyDown}>
      <CommandInput onValueChange={handleSearch} placeholder={placeholder} />
      <CommandEmpty>{tShared('notFound')}</CommandEmpty>
      <CommandGroup
        ref={parentRef}
        style={{
          height: height,
          width: '100%',
          overflow: 'auto',
        }}
      >
        <CommandList
          style={{
            height: `${virtualizer.getTotalSize()}px`,
            width: '100%',
            position: 'relative',
          }}
          className="max-h-full"
        >
          {isLoading && <CommandListSkeleton />}
          {!isLoading &&
            virtualizer.getVirtualItems().map((virtualOption) => (
              <CommandItem
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: `${virtualOption.size}px`,
                  transform: `translateY(${virtualOption.start}px)`,
                }}
                className="!opacity-100 !pointer-events-auto"
                key={filteredOptions[virtualOption.index].value}
                value={filteredOptions[virtualOption.index].value}
                onSelect={onSelectOption}
              >
                <Check
                  className={cn(
                    'mr-2 h-4 w-4',
                    selectedOption ===
                      filteredOptions[virtualOption.index].value
                      ? 'opacity-100'
                      : 'opacity-0',
                  )}
                />
                {filteredOptions[virtualOption.index].displayValue ??
                  filteredOptions[virtualOption.index].value}
              </CommandItem>
            ))}
        </CommandList>
      </CommandGroup>
    </Command>
  );
};

const CommandListSkeleton = () => {
  return Array(5)
    .fill(0)
    .map((_, idx) => (
      <CommandItem key={idx}>
        <Skeleton className="h-10 p-2 rounded w-full" />
      </CommandItem>
    ));
};

interface VirtualizedComboboxProps {
  options: Option[];
  searchPlaceholder?: string;
  width?: string;
  height?: string;
  defaultValue?: string;
  isLoading?: boolean;
  onValueChange?: (data: string) => void;
  disabled?: boolean;
}

export function VirtualizedCombobox({
  options,
  onValueChange,
  defaultValue = '',
  isLoading = false,
  searchPlaceholder = 'Search items...',
  width = '300px',
  height = '400px',
  disabled = false,
}: VirtualizedComboboxProps) {
  const [open, setOpen] = React.useState<boolean>(false);
  const [selectedOption, setSelectedOption] =
    React.useState<string>(defaultValue);

  const displayedValue = React.useMemo(() => {
    const foundOption = options.find(
      (option) => option.value === selectedOption,
    );
    if (selectedOption && foundOption) {
      return foundOption.displayValue ?? foundOption.value;
    }
    return searchPlaceholder;
  }, [options, selectedOption, searchPlaceholder]);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="justify-between w-full"
          disabled={disabled}
        >
          {displayedValue}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        avoidCollisions={false}
        className="p-0"
        style={{ width: width }}
      >
        <VirtualizedCommand
          height={height}
          options={options}
          placeholder={searchPlaceholder}
          selectedOption={selectedOption}
          isLoading={isLoading}
          onSelectOption={(currentValue) => {
            const value = currentValue === selectedOption ? '' : currentValue;
            setSelectedOption(value);
            onValueChange?.(value);
            setOpen(false);
          }}
        />
      </PopoverContent>
    </Popover>
  );
}
