import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../ui/form';
import { useStepper } from '../../ui/stepper';
import { useTranslation } from 'react-i18next';
import { FormDataProps } from '../registration-survey';

import { cn } from '@/lib/utils';
import { SurveyFormActions } from '../form-actions';
import { useMemo, useState } from 'react';
import { VirtualizedCombobox } from '@/components/ui/virtualized-combobox';
import { useQuery } from '@tanstack/react-query';
import {
  countriesQueryOptions,
  statesQueryOptions,
} from '@/lib/queries/location';
import { ClearLabeledInput } from '@/components/ui/input';

const ResidenceSelectionFormSchema = z
  .object({
    country: z.string().min(1, 'required'),
    state: z.string(),
    city: z.string(),
  })
  .refine((data) => data.country === 'United States' || data.city != '', {
    message: 'required',
    path: ['city'],
  })
  .refine((data) => data.country !== 'United States' || data.state != '', {
    message: 'required',
    path: ['state'],
  });

type ResidenceSelectionFormData = z.infer<typeof ResidenceSelectionFormSchema>;

export const ResidenceSelectionForm = ({
  updateDataCallback,
  className,
  formData,
}: FormDataProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'survey' });
  const { t: tError } = useTranslation('translation', {
    keyPrefix: 'error.form',
  });
  const { nextStep } = useStepper();

  const [country, setCountry] = useState(formData.country ?? '');

  const { data: countries, isLoading: isCountriesLoading } = useQuery(
    countriesQueryOptions,
  );

  const currentCountry = useMemo(
    () => countries.find((value) => value.name === country),
    [countries, country],
  );

  const { data: states, isLoading: isStatesLoading } = useQuery(
    statesQueryOptions(currentCountry?.code, currentCountry?.id?.toString()),
  );

  const form = useForm<ResidenceSelectionFormData>({
    resolver: zodResolver(ResidenceSelectionFormSchema),
    defaultValues: {
      country: formData.country ?? '',
      city: formData.city ?? '',
      state: formData.state ?? '',
    },
  });

  function onSubmit(data: ResidenceSelectionFormData) {
    updateDataCallback(data);
    nextStep();
  }

  const countryData = useMemo(
    () =>
      countries.map((country) => {
        return {
          value: country.name,
          displayValue: country.name,
        };
      }),
    [countries],
  );

  const statesData = states.map((state) => ({
    value: state.name,
    displayValue: state.name,
  }));

  const hasStates = statesData.length > 0;

  const onCountryChange =
    (callback: (data: string) => void) => (data: string) => {
      callback(data);
      setCountry(data);
      if (form.getValues().city != null && form.getValues().city != '') {
        form.resetField('city', { defaultValue: '' });
      }
      if (form.getValues().state != null && form.getValues().state != '') {
        form.resetField('state', { defaultValue: '' });
      }
      const formData = form.getValues();
      updateDataCallback(formData);
    };

  const onStateChange =
    (callback: (data: string) => void) => (data: string) => {
      callback(data);
      const formData = form.getValues();
      updateDataCallback(formData);
    };

  const onInputChange =
    (callback: (data: string) => void) =>
    (event: React.FormEvent<HTMLInputElement>) => {
      callback(event.currentTarget.value);
      const formData = form.getValues();
      updateDataCallback(formData);
    };

  return (
    <div className="flex w-full justify-center">
      <div className={cn('flex flex-col gap-8', className)}>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
            <FormField
              control={form.control}
              name="country"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('residence.country.label')}</FormLabel>
                  <VirtualizedCombobox
                    isLoading={isCountriesLoading}
                    onValueChange={onCountryChange(field.onChange)}
                    defaultValue={field.value}
                    options={countryData}
                  />
                  <FormMessage translationFunction={tError} />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="state"
              render={({ field }) => (
                <FormItem hidden={!hasStates}>
                  <FormLabel>{t(`residence.state.label`)}</FormLabel>{' '}
                  <VirtualizedCombobox
                    isLoading={isStatesLoading}
                    onValueChange={onStateChange(field.onChange)}
                    defaultValue={field.value}
                    options={statesData}
                    disabled={country === ''}
                  />
                  <FormMessage translationFunction={tError} />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="city"
              disabled={isStatesLoading}
              render={({ field }) => (
                <FormItem hidden={hasStates}>
                  <ClearLabeledInput
                    id="city"
                    placeholder={t('residence.city.label')}
                    {...field}
                    onChange={onInputChange(field.onChange)}
                  />
                  <FormMessage translationFunction={tError} />
                </FormItem>
              )}
            />
            <SurveyFormActions type="submit" />
          </form>
        </Form>
      </div>
    </div>
  );
};

export default ResidenceSelectionForm;
