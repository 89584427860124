import { queryOptions } from '@tanstack/react-query';
import client, { APIResponse } from './client';
import { Country, State } from '../models';

export type StatesResponse = {
  states: State[];
};

export function statesQueryOptions(countryCode?: string, countryId?: string) {
  return queryOptions({
    queryKey: ['state', countryId],
    queryFn: () => fetchStates(countryId),
    enabled: countryId != null && countryCode == 'US', // only fetch for US right now
    initialData: [],
  });
}

async function fetchStates(countryId?: string): Promise<State[]> {
  const response = await client.get<APIResponse<StatesResponse>>(
    `location/countries/${countryId}/states`,
  );
  return response.data.data.states;
}

export type CountriesResponse = {
  countries: Country[];
};

export const countriesQueryOptions = queryOptions({
  queryKey: ['countries'],
  queryFn: () => fetchCountries(),
  initialData: [],
});

async function fetchCountries(): Promise<Country[]> {
  const response =
    await client.get<APIResponse<CountriesResponse>>(`location/countries`);
  return response.data.data.countries;
}
