import { Link, useNavigate } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import { cn } from '@/lib/utils';
import { ClearLabeledInput } from '../../ui/input';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { parseAuthError, sendPasswordResetLink } from '@/lib/firebase';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '../../ui/form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '../../ui/button';
import { toast } from '@/components/ui/use-toast';
import { WithClassName } from '@/types/with-classname';

const formSchema = z
  .object({
    email: z.string().email(),
  })
  .required();

type ResetFormData = z.infer<typeof formSchema>;

export default function ResetPasswordForm({ className }: WithClassName) {
  const { t } = useTranslation('translation', { keyPrefix: 'auth' });
  const { t: tError } = useTranslation('translation', { keyPrefix: 'error' });

  const navigate = useNavigate({ from: '/auth/reset' });

  const form = useForm<ResetFormData>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
    },
  });

  const resetPassword = async (email: string) => {
    form.clearErrors();
    try {
      await sendPasswordResetLink(email);
      navigate({ to: '/forgot-password/confirmation' });
    } catch (error) {
      const message = parseAuthError(error);
      toast({
        title: tError('type.registerError'),
        description: tError(message ?? 'message.unknown'),
      });
    }
  };

  const onSubmit = (values: ResetFormData) => {
    resetPassword(values.email);
  };

  return (
    <Form {...form}>
      <form
        className={cn('flex flex-col justify-center gap-8 pt-2', className)}
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <ClearLabeledInput
                  autoComplete="email"
                  id="userEmail"
                  type="email"
                  placeholder={t('email')}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button type="submit">{t('requestResetLink')}</Button>
        <Link to="/auth">
          <Button type="button" variant="secondary" className="w-full">
            {t('backToLogin')}
          </Button>
        </Link>
      </form>
    </Form>
  );
}
