import * as React from 'react';

import { cn } from '@/lib/utils';
import { Label } from './label';
import { useState } from 'react';
import { Button } from './button';
import { EyeIcon, EyeOffIcon } from 'lucide-react';

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  hidden?: boolean;
};

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(
          'flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
          className,
        )}
        ref={ref}
        {...props}
      />
    );
  },
);
Input.displayName = 'Input';

const ClearLabeledInput = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, placeholder, id, hidden, ...props }, ref) => {
    const isPassword = type === 'password';
    const disabled = props.disabled;

    const [showPassword, setShowPassword] = useState(false);

    return (
      <div className={cn('relative z-0', hidden ? 'hidden' : 'block')}>
        <input
          id={id}
          type={isPassword ? (showPassword ? 'text' : 'password') : type}
          className={cn(
            'px-2.5 pb-2.5 pt-4 block h-full w-full text-sm border-input bg-transparent border-0 border-b focus:outline-none focus:ring-0 peer disabled:cursor-not-allowed disabled:opacity-50',
            className,
          )}
          ref={ref}
          {...props}
          placeholder=""
        />
        <Label
          className="cursor-auto absolute text-sm  duration-300 transform -translate-y-6 scale-75 top-3 z-10 origin-[0] peer-focus:start-0 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
          htmlFor={id}
        >
          {placeholder}
        </Label>
        {isPassword && (
          <Button
            type="button"
            variant="ghost"
            size="sm"
            className="absolute right-0 top-0 h-full px-3 py-2 hover:bg-transparent"
            onClick={() => setShowPassword((prev) => !prev)}
            disabled={disabled}
          >
            {showPassword && !disabled ? (
              <EyeIcon className="h-4 w-4" aria-hidden="true" />
            ) : (
              <EyeOffIcon className="h-4 w-4" aria-hidden="true" />
            )}
            <span className="sr-only">
              {showPassword ? 'Hide password' : 'Show password'}
            </span>
          </Button>
        )}
      </div>
    );
  },
);
ClearLabeledInput.displayName = 'Input';

export { Input, ClearLabeledInput };
