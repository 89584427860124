import { Outlet, createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/analytics/')({
  component: () => <Outlet />,
  beforeLoad: () => {
    throw redirect({
      to: '/analytics/courses',
      from: '/analytics',
    });
  },
  pendingComponent: () => <></>,
});
