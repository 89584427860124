import { RouterProvider } from '@tanstack/react-router';

import { useAuth } from './components/providers/auth';
import { router } from './router';
import Providers from './components/providers';
import { useEffect } from 'react';
import { useSidebar } from './components/providers/sidebar';

function InnerApp() {
  const auth = useAuth();
  const sidebar = useSidebar();

  useEffect(() => {
    router.invalidate();
  }, [auth]);

  return <RouterProvider router={router} context={{ auth, sidebar }} />;
}

function App() {
  return (
    <Providers>
      <InnerApp />
    </Providers>
  );
}

export default App;
