import { useTranslation } from 'react-i18next';
import { Input } from '../ui/input';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../ui/form';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { UserProfile } from '@/lib/models';
import { Button } from '../ui/button';
import { useUpdateCurrentProfile } from '@/lib/queries/user';
import { useToast } from '../ui/use-toast';

export type UserProfileSettingsProps = {
  profile: UserProfile;
};

const formSchema = z
  .object({
    firstName: z.string().min(1, 'First Name is required.'),
    lastName: z.string().min(1, 'Last Name is required.'),
  })
  .required();

type UserProfileFormData = z.infer<typeof formSchema>;

export function UserProfileSettings({ profile }: UserProfileSettingsProps) {
  const { t } = useTranslation();
  const updateProfile = useUpdateCurrentProfile();

  const form = useForm<UserProfileFormData>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      firstName: profile.first_name,
      lastName: profile.last_name,
    },
  });

  const { toast } = useToast();

  const onSubmit = (data: UserProfileFormData) => {
    updateProfile.mutate(data, {
      onSuccess: () =>
        toast({
          title: t('shared.success'),
          description: t('account.profile.success'),
        }),
      onError: () =>
        toast({
          title: t('shared.error'),
          description: t('account.profile.error'),
          variant: 'destructive',
        }),
    });
  };

  return (
    <Form {...form}>
      <form
        className="flex flex-col gap-4"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <FormField
          control={form.control}
          name="firstName"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('shared.firstName')}</FormLabel>
              <FormControl>
                <Input
                  autoComplete="given-name"
                  type="text"
                  placeholder="John"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="lastName"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('shared.lastName')}</FormLabel>
              <FormControl>
                <Input
                  autoComplete="family-name"
                  type="text"
                  placeholder="Doe"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button loading={updateProfile.isPending} type="submit">
          {t('account.profile.update')}
        </Button>
      </form>
    </Form>
  );
}
