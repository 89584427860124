import { Link, useNavigate } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import { cn } from '@/lib/utils';
import { ClearLabeledInput } from '../../ui/input';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { parseAuthError, signInWithEmailAndPass } from '@/lib/firebase';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '../../ui/form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '../../ui/button';
import { useToast } from '@/components/ui/use-toast';
import { WithClassName } from '@/types/with-classname';

export type CredentialsLoginFormProps = {
  onSuccess?: () => void;
} & WithClassName;

const formSchema = z
  .object({
    email: z.string().email({ message: 'Email is invalid!' }),
    password: z.string().min(1).max(50),
  })
  .required();

type LoginFormData = z.infer<typeof formSchema>;

export default function CredentialsLoginForm({
  className,
  onSuccess,
}: CredentialsLoginFormProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'auth' });
  const { t: tShared } = useTranslation('translation', { keyPrefix: 'shared' });

  const { t: tError } = useTranslation('translation', { keyPrefix: 'error' });

  const { toast } = useToast();
  const navigate = useNavigate({ from: '/auth' });

  const form = useForm<LoginFormData>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const loginWithEmailAndPassword = async (email: string, password: string) => {
    try {
      await signInWithEmailAndPass(email, password);
      onSuccess?.();
      navigate({ from: '/auth', to: '/' });
    } catch (error) {
      const message = parseAuthError(error);

      toast({
        title: tError('type.loginError'),
        description: tError(message ?? 'message.unknown'),
      });
    }
  };

  const onSubmit = (values: LoginFormData) => {
    loginWithEmailAndPassword(values.email, values.password);
  };

  return (
    <Form {...form}>
      <form
        className={cn('flex flex-col justify-center gap-8 pt-2', className)}
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <ClearLabeledInput
                  autoComplete="email"
                  id="email"
                  type="email"
                  placeholder={tShared('email')}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <ClearLabeledInput
                  autoComplete="current-password"
                  id="password"
                  type="password"
                  placeholder={t('password')}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Link
          to="/forgot-password/reset"
          from="/auth"
          className="ml-auto inline-block text-sm underline"
        >
          {t('forgotPassword')}
        </Link>
        <Button type="submit">{t('login')}</Button>
      </form>
    </Form>
  );
}
