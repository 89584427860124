import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from '../../ui/form';
import { useTranslation } from 'react-i18next';
import { FormDataProps } from '../registration-survey';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../ui/select';
import { cn } from '@/lib/utils';
import { SurveyFormActions } from '../form-actions';
import { ClearLabeledInput } from '../../ui/input';
import { useEffect, useMemo, useState } from 'react';
import {
  AlertDialogHeader,
  AlertDialogFooter,
} from '@/components/ui/alert-dialog';
import {
  AlertDialog,
  AlertDialogTrigger,
  AlertDialogContent,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogCancel,
  AlertDialogAction,
} from '../../ui/alert-dialog';

const PrimaryWorkEnvironmentResidenceSelectionFormSchema = z.object({
  primary_work_environment: z.string().min(1, 'required'),
  custom_primary_work_environment: z.string(),
});

type PrimaryWorkEnvironmentResidenceSelectionFormData = z.infer<
  typeof PrimaryWorkEnvironmentResidenceSelectionFormSchema
>;

export const PrimaryWorkEnvironmentSelectionForm = ({
  updateDataCallback,
  className,
  disableActions,
  config,
  formData,
}: FormDataProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'survey' });
  const { t: tShared } = useTranslation('translation', { keyPrefix: 'shared' });
  const { t: tError } = useTranslation('translation', {
    keyPrefix: 'error.form',
  });

  const [otherSelected, setOtherSelected] = useState(false);
  const [open, setOpen] = useState(false);

  const form = useForm<PrimaryWorkEnvironmentResidenceSelectionFormData>({
    resolver: zodResolver(PrimaryWorkEnvironmentResidenceSelectionFormSchema),
    defaultValues: {
      primary_work_environment: formData.primary_work_environment ?? '',
      custom_primary_work_environment:
        formData.custom_primary_work_environment ?? '',
    },
  });

  useEffect(() => {
    setOtherSelected(formData.primary_work_environment === 'other');
  }, []);

  function onSubmit(data: PrimaryWorkEnvironmentResidenceSelectionFormData) {
    if (open === false) {
      setOpen(true);
      return;
    }
    updateDataCallback(data, true);
  }

  const otherOption = {
    name: 'other',
    displayName: tShared('other'),
  };

  const options = useMemo(() => {
    return [...config.work_environments, otherOption];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config.work_environments]);

  const primaryWorkEnvironmentOnChange =
    (callback: (data: string) => void) => (data: string) => {
      callback(data);
      if (data === 'other') {
        setOtherSelected(true);
      } else {
        setOtherSelected(false);
        form.resetField('custom_primary_work_environment', {
          defaultValue: '',
        });
      }
      const formData = form.getValues();
      updateDataCallback(formData);
    };

  return (
    <div className="flex w-full justify-center">
      <div className={cn('flex flex-col gap-8', className)}>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
            <FormField
              control={form.control}
              name="primary_work_environment"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('workEnv.primary.label')}</FormLabel>
                  <Select
                    onValueChange={primaryWorkEnvironmentOnChange(
                      field.onChange,
                    )}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue
                          placeholder={t('workEnv.primary.select')}
                        />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {options.map((data) => (
                        <SelectItem key={data.name} value={data.name}>
                          {data.displayName ?? data.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage translationFunction={tError} />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="custom_primary_work_environment"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <ClearLabeledInput
                      hidden={!otherSelected}
                      id="custom_primary_work_environment"
                      placeholder={t('workEnv.custom.label')}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage translationFunction={tError} />
                </FormItem>
              )}
            />

            <SurveyFormActions type="submit" disableActions={disableActions} />

            <AlertDialog open={open} onOpenChange={setOpen}>
              <AlertDialogTrigger style={{ display: 'none' }} />

              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>{t('finish.sure')}</AlertDialogTitle>
                  <AlertDialogDescription>
                    {t('finish.description')}
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel onClick={() => setOpen(false)}>
                    {tShared('no')}
                  </AlertDialogCancel>
                  <AlertDialogAction onClick={() => onSubmit(form.getValues())}>
                    {tShared('yes')}
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          </form>
        </Form>
      </div>
    </div>
  );
};
