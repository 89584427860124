import { cn } from '@/lib/utils';
import { WithClassName } from '@/types/with-classname';
import { ReactNode } from 'react';

export type PageWithTitleLayoutProps = WithClassName & {
  children?: ReactNode;
  title: string;
};

export const PageWithTitleLayout = ({
  className,
  children,
  title,
}: PageWithTitleLayoutProps) => {
  return (
    <div className={cn('flex flex-col w-full gap-4', className)}>
      <span className="text-3xl font-bold">{title}</span>
      {children}
    </div>
  );
};
