export interface RouteDetails {
  label: string;
  overwriteRouteId?: string;
}

export const BreadcrumbRouteDictionary: Record<string, RouteDetails> = {
  '/': {
    label: 'home',
  },
  '/courses': {
    label: 'courses',
  },
  '/courses/$courseId': {
    label: 'modules',
    overwriteRouteId: '/courses/$courseId/modules',
  },
  '/courses/$courseId/modules/$moduleId': {
    label: 'module',
  },
  '/users': {
    label: 'users',
  },
  '/analytics': {
    label: 'analytics.base',
  },
  '/analytics/_layout_analytics/courses': {
    label: 'courses',
  },
  '/analytics/_layout_analytics/users': {
    label: 'users',
  },
  '/analytics/_layout_table/users': {
    label: 'users',
    overwriteRouteId: '/analytics/users',
  },
  '/analytics/_layout_table/courses': {
    label: 'courses',
    overwriteRouteId: '/analytics/courses',
  },
  '/analytics/_layout_table/courses/$courseId/table/$stat': {
    label: 'analytics.table',
  },
  '/analytics/_layout_table/users/table/$stat': {
    label: 'analytics.table',
  },
  '/account': {
    label: 'myAccount',
  },
  '/account/profile': {
    label: 'profile',
  },
  '/account/preferences': {
    label: 'preferences',
  },
};
