import { cn } from '@/lib/utils';
import { Card } from '../../ui/card';
import CredentialsLoginForm from './email-login.form';
import GoogleFormLogin from './google-login';
import { Separator } from '../../ui/separator';
import { useEventTracking } from '@/lib/queries/events';
import { AuthProvider, buildSignInEvent } from '@/lib/models';
import { WithClassName } from '@/types/with-classname';

export default function LoginCard({ className }: WithClassName) {
  const { track } = useEventTracking();

  const onLoginSuccess = (provider: AuthProvider) => {
    track(buildSignInEvent(provider));
  };

  return (
    <Card
      className={cn(
        'flex flex-col p-4 gap-4 shadow-md hover:shadow-xl overflow-hidden transition-shadow duration-300',
        className,
      )}
    >
      <CredentialsLoginForm onSuccess={() => onLoginSuccess('credentials')} />
      <div className="flex flex-row relative justify-center items-center gap-2">
        <Separator className="w-5/12 relative" />
        or
        <Separator className="w-5/12 relative" />
      </div>
      <GoogleFormLogin onSuccess={() => onLoginSuccess('google')} />
    </Card>
  );
}
