import { AnalyticsStatsSkeleton } from '@/components/analytics/analytics-stats';
import { UsersAnalytics } from '@/components/analytics/users-analytics';
import { AxiosErrorComponent } from '@/components/ui/errors/axios-error';
import { userStatsQueryOptions } from '@/lib/queries/analytics/analytics-users';
import { onboardingConfigQueryOptions } from '@/lib/queries/onboarding';
import { useSuspenseQuery } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/analytics/_layout_analytics/users')({
  component: AnalyticsChapters,
  loader: async ({ context: { queryClient } }) => {
    const onBoardingPromise = queryClient.ensureQueryData(
      onboardingConfigQueryOptions,
    );
    const userStatsPromise = queryClient.ensureQueryData(userStatsQueryOptions);

    const [onBoardingData, userStatData] = await Promise.all([
      onBoardingPromise,
      userStatsPromise,
    ]);

    return { onBoardingData, userStatData };
  },
  pendingMs: 100,
  errorComponent: AxiosErrorComponent,
  pendingComponent: AnalyticsUsersSkeleton,
});

function AnalyticsChapters() {
  const {
    data: { config },
  } = useSuspenseQuery(onboardingConfigQueryOptions);

  const {
    data: { stats },
  } = useSuspenseQuery(userStatsQueryOptions);

  return <UsersAnalytics config={config} stats={stats} />;
}

function AnalyticsUsersSkeleton() {
  return <AnalyticsStatsSkeleton noMetric numberOfCards={4} />;
}
