import { queryOptions } from '@tanstack/react-query';
import api from '../client';

export interface UserStatsProperties {
  users_completed_media: number;
  users_no_media: number;
  users_started_not_completed_media: number;
  users: number;
}

export interface UserStatsByProfession extends UserStatsProperties {
  profession: string | null;
}

export interface UserStats extends UserStatsProperties {}

export type UserStatsResponse = {
  stats: {
    byProfession: UserStatsByProfession[];
    total: UserStats;
  };
};

export const userStatsQueryOptions = queryOptions({
  queryKey: ['userstats'],
  queryFn: () => fetchUserStats(),
});

async function fetchUserStats(): Promise<UserStatsResponse> {
  return api.get(`/userstats/summary`).then((response) => response.data.data);
}

export interface UserFully {
  id: number;
  email: string;
  first_name: string | null;
  last_name: string | null;
}

export interface UserStatsFullyResponse {
  users: UserFully[];
}

export function userStatsFullyQueryOptions(stat: string) {
  return queryOptions({
    queryKey: ['userstats', 'fully', stat],
    queryFn: () => fetchUserStatsFully(stat),
  });
}

async function fetchUserStatsFully(
  stat: string,
): Promise<UserStatsFullyResponse> {
  return api
    .get(`/userstats/fully`, { params: { stat } })
    .then((response) => response.data.data);
}
