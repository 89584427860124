import {
  Media,
  buildVideoDurationEvent,
  buildVideoPauseEvent,
  buildVideoPlayEvent,
  buildVideoProgressEvent,
  buildVideoSeekEvent,
  buildVideoStartEvent,
} from '@/lib/models';
import { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { Transcript } from './transcript';
import { useEventTracking } from '@/lib/queries/events';

export default function VideoMedia({ media }: { media: Media }) {
  if (!media.url) {
    throw new Error('Media content is missing');
  }

  const { track } = useEventTracking();

  const [isTranscriptOpen, setIsTranscriptOpen] = useState(false);
  const [isPlayerReady, setPlayerReady] = useState(false);
  const playerRef = useRef<ReactPlayer>(null);

  useEffect(() => {
    if (isPlayerReady && playerRef.current) {
      playerRef.current.seekTo(media.latest_playtime_percentage, 'fraction');
    }
  }, [isPlayerReady, media]);

  return (
    <div className="flex flex-col gap-4 desktop:gap-8 flex-1 max-w-[1650px]">
      <div className="relative aspect-video">
        <ReactPlayer
          className="[&>video]:absolute [&>video]:bg-black"
          style={{ position: 'absolute', top: 0, left: 0 }}
          controls
          width="100%"
          height="100%"
          url={media.url}
          onReady={() => setPlayerReady(true)}
          onPlay={() => track(buildVideoPlayEvent(media.id))}
          onStart={() => track(buildVideoStartEvent(media.id))}
          onPause={() => track(buildVideoPauseEvent(media.id))}
          onSeek={(seconds) => track(buildVideoSeekEvent(media.id, seconds))}
          onProgress={({ played, playedSeconds }) =>
            track(buildVideoProgressEvent(media.id, played, playedSeconds))
          }
          onDuration={(duration) =>
            track(buildVideoDurationEvent(media.id, duration))
          }
          ref={playerRef}
        />
      </div>

      {media.transcript && (
        <Transcript
          isTranscriptOpen={isTranscriptOpen}
          setIsTranscriptOpen={setIsTranscriptOpen}
          transcript={media.transcript}
        />
      )}
    </div>
  );
}
