import { Button } from '@/components/ui/button';
import {
  FormField,
  FormItem,
  FormControl,
  FormMessage,
} from '@/components/ui/form';
import { ClearLabeledInput, Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { TableRow, TableCell } from '@/components/ui/table';
import { USER_ROLES } from '@/lib/models';
import { X } from 'lucide-react';
import React from 'react';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { UsersDialogRowFormData } from './users-dialog';

interface UsersDialogRowProps {
  onRoleChange: (index: number, role: string) => void;
  removeElementAtIndex: (index: number) => void;
  onEmailChange: (index: number, email: string) => void;
  index: number;
  formControl: Control<UsersDialogRowFormData>;
  disableEmail: boolean;
}

export const UsersDialogRow = ({
  onEmailChange,
  onRoleChange,
  formControl,
  removeElementAtIndex,
  index,
  disableEmail,
}: UsersDialogRowProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'users' });
  const { t: tError } = useTranslation('translation', {
    keyPrefix: 'error.form',
  });

  const onFormEmailChange =
    (callback: (data: string) => void) =>
    (event: React.FormEvent<HTMLInputElement>) => {
      const email = event.currentTarget.value;
      callback(email);
      onEmailChange(index, email);
    };

  const onFormSelectChange =
    (callback: (data: string) => void) => (data: string) => {
      callback(data);
      onRoleChange(index, data);
    };

  const handleDelete = () => {
    removeElementAtIndex(index);
  };

  return (
    <TableRow className="hover:bg-transparent">
      <TableCell>
        <FormField
          control={formControl}
          name={`users.${index}.id`}
          render={({ field }) => (
            <FormItem hidden>
              <FormControl>
                <Input disabled {...field} hidden></Input>
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={formControl}
          name={`users.${index}.email`}
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <ClearLabeledInput
                  disabled={disableEmail}
                  autoComplete="email"
                  id="email"
                  type="email"
                  {...field}
                  onChange={onFormEmailChange(field.onChange)}
                />
              </FormControl>
              <FormMessage translationFunction={tError} />
            </FormItem>
          )}
        />
      </TableCell>
      <TableCell>
        <FormField
          control={formControl}
          name={`users.${index}.role`}
          render={({ field }) => (
            <FormItem>
              <Select
                onValueChange={onFormSelectChange(field.onChange)}
                defaultValue={field.value}
              >
                <SelectTrigger className="capitalize">
                  <SelectValue placeholder={t('roleFilterText')} />
                </SelectTrigger>
                <SelectContent>
                  {USER_ROLES.map((role) => (
                    <SelectItem className="capitalize" key={role} value={role}>
                      {role}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <FormMessage translationFunction={tError} />
            </FormItem>
          )}
        />
      </TableCell>
      {!disableEmail && (
        <TableCell className=" p-0">
          <Button variant="ghost" type="button" onClick={handleDelete}>
            <X />
          </Button>
        </TableCell>
      )}
    </TableRow>
  );
};
