import { UIViewLayoutDictionary } from '@/constants/view-layout';
import { useMediaQuery } from '@react-hook/media-query';
import {
  BreadcrumbEllipsis,
  BreadcrumbItem,
  BreadcrumbSeparator,
} from '../ui/breadcrumb';
import { Button } from '../ui/button';
import {
  DrawerTrigger,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  DrawerDescription,
  DrawerFooter,
  DrawerClose,
  Drawer,
} from '../ui/drawer';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu';
import { useTranslation } from 'react-i18next';
import { Breadcrumb } from './breadcrumb-navigation';
import { Link } from '@tanstack/react-router';

export interface CollapsedBreadcrumbNavigationProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  breadcrumbs: Breadcrumb[];
}

const CollapsedBreadcrumbNavigationDesktop = ({
  open,
  setOpen,
  breadcrumbs,
}: CollapsedBreadcrumbNavigationProps) => {
  return (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger
        className="flex items-center gap-1"
        aria-label="Toggle menu"
      >
        <BreadcrumbEllipsis className="h-4 w-4" />
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start">
        {breadcrumbs.slice(1, -2).map((crumb, index) => (
          <DropdownMenuItem key={index}>
            <Link to={crumb.route} params={crumb.params} search={crumb.search}>
              {crumb.label}
            </Link>
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

const CollapsedBreadcrumbNavigationMobile = ({
  open,
  setOpen,
  breadcrumbs,
}: CollapsedBreadcrumbNavigationProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'navigation' });
  const { t: tShared } = useTranslation('translation', { keyPrefix: 'shared' });

  return (
    <Drawer open={open} onOpenChange={setOpen}>
      <DrawerTrigger aria-label="Toggle Menu">
        <BreadcrumbEllipsis className="h-4 w-4" />
      </DrawerTrigger>
      <DrawerContent>
        <DrawerHeader className="text-left">
          <DrawerTitle>{t('navigateTo')}</DrawerTitle>
          <DrawerDescription>{t('navigateToSelect')}</DrawerDescription>
        </DrawerHeader>
        <div className="grid gap-1 px-4">
          {breadcrumbs.slice(1, -2).map((crumb) => (
            <Link to={crumb.route} params={crumb.params} search={crumb.search}>
              {crumb.label}
            </Link>
          ))}
        </div>
        <DrawerFooter className="pt-4">
          <DrawerClose asChild>
            <Button variant="outline">{tShared('close')}</Button>
          </DrawerClose>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export const CollapsedBreadcrumbNavigation = ({
  open,
  setOpen,
  breadcrumbs,
}: CollapsedBreadcrumbNavigationProps) => {
  const isDesktop = useMediaQuery(
    `(min-width: ${UIViewLayoutDictionary.desktopMinWidth})`,
  );

  return (
    <>
      <BreadcrumbItem>
        {isDesktop ? (
          <CollapsedBreadcrumbNavigationDesktop
            open={open}
            setOpen={setOpen}
            breadcrumbs={breadcrumbs}
          />
        ) : (
          <CollapsedBreadcrumbNavigationMobile
            open={open}
            setOpen={setOpen}
            breadcrumbs={breadcrumbs}
          />
        )}
      </BreadcrumbItem>
      <BreadcrumbSeparator />
    </>
  );
};
