import { queryOptions } from '@tanstack/react-query';
import api, { APIResponse } from './client';
import { Chapter, ChapterWithContent } from '../models';

export type ChaptersResponse = {
  chapters: Chapter[];
};

export function chaptersQueryOptions(courseId: string, moduleId: string) {
  return queryOptions({
    queryKey: ['chapters', courseId, moduleId],
    queryFn: () => fetchChapters(courseId, moduleId),
  });
}

async function fetchChapters(
  courseId: string,
  moduleId: string,
): Promise<ChaptersResponse> {
  return api
    .get<
      APIResponse<ChaptersResponse>
    >(`/courses/${courseId}/modules/${moduleId}/chapters/me`)
    .then((response) => response.data.data);
}

export type ChapterResponse = {
  chapter: ChapterWithContent;
};

export function chapterQueryOptions(
  courseId: string,
  moduleId: string,
  chapterId: string | undefined,
) {
  return queryOptions({
    queryKey: ['chapter', courseId, moduleId, chapterId],
    queryFn: () => fetchChapterById(courseId, moduleId, chapterId!),
    enabled: !!chapterId,
  });
}

async function fetchChapterById(
  courseId: string,
  moduleId: string,
  chapterId: string,
): Promise<ChapterResponse> {
  return api
    .get<
      APIResponse<ChapterResponse>
    >(`/courses/${courseId}/modules/${moduleId}/chapters/${chapterId}/me`)
    .then((response) => response.data.data);
}
