import { DefaultItemsLayout } from '@/components/layouts/default-items-layout';
import {
  ModuleCard,
  ModuleCardListSkeleton,
} from '@/components/module/module-card';
import { AxiosErrorComponent } from '@/components/ui/errors/axios-error';
import { Module } from '@/lib/models';
import { modulesQueryOptions } from '@/lib/queries/module';
import { useSuspenseQuery } from '@tanstack/react-query';
import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/courses/$courseId/modules/')({
  component: Modules,
  beforeLoad: ({ context: { auth } }) => {
    if (!auth.isSignedIn) {
      throw redirect({
        to: '/auth',
        from: '/courses/$courseId/modules',
        search: {
          redirect: '/courses/$courseId/modules/',
        },
      });
    }
  },
  loader: ({ context: { queryClient }, params }) =>
    queryClient.ensureQueryData(modulesQueryOptions(params.courseId)),
  errorComponent: AxiosErrorComponent,
  pendingComponent: ModuleCardListSkeleton,
});

function Modules() {
  const { courseId } = Route.useParams();

  const {
    data: { modules },
  } = useSuspenseQuery(modulesQueryOptions(courseId));

  const navigate = Route.useNavigate();

  const onSelectModule = (module: Module) => {
    navigate({
      from: '/courses/$courseId/modules',
      to: '/courses/$courseId/modules/$moduleId',
      params: { moduleId: module.id.toString() },
      search: {
        chapter: 0,
      },
    });
  };

  return (
    <DefaultItemsLayout>
      {modules.map((module) => (
        <ModuleCard
          key={module.id}
          module={module}
          onClick={() => onSelectModule(module)}
        />
      ))}
    </DefaultItemsLayout>
  );
}
