import {
  AccountPage,
  AccountSettings,
} from '@/components/account/account-settings';
import {
  createFileRoute,
  redirect,
  useRouterState,
} from '@tanstack/react-router';

export const Route = createFileRoute('/account')({
  component: AccountRoute,
  beforeLoad: ({ context: { auth } }) => {
    if (!auth.isAdmin) {
      throw redirect({
        to: '/',
        from: '/account',
      });
    }
  },
  loader: ({ location }) => {
    return { tab: location.pathname.split('/').pop() };
  },
});

function AccountRoute() {
  const { location } = useRouterState();
  const tab = location.pathname.split('/').pop();

  return <AccountSettings tab={tab as AccountPage} />;
}
