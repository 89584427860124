import { cn } from '@/lib/utils';
import { Step, Stepper } from '../ui/stepper';
import { LanguageSelectionForm } from './form/language-selection-form';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { ChildCareSelectionForm } from './form/child-care-selection-form';
import PersonalInformationForm from './form/personal-information-form';
import { UIViewLayoutDictionary } from '@/constants/view-layout';
import { ProfessionSelectionForm } from './form/profession-selection-form';
import { WithClassName } from '@/types/with-classname';
import { Skeleton } from '../ui/skeleton';
import { PrimaryWorkEnvironmentSelectionForm } from './form/primary-work-environment-form';
import {
  UserOnboardingData,
  useUserOnboarding,
} from '@/lib/queries/onboarding';
import { useToast } from '../ui/use-toast';
import { useNavigate } from '@tanstack/react-router';
import { useQueryClient } from '@tanstack/react-query';
import { mapDataToConfig } from './form/utils';
import { OnboardingConfig } from '@/lib/models';
import { userProfileQueryOptions } from '@/lib/queries/user';
import ResidenceSelectionForm from './form/residence-selection-form';

interface RegistrationSurveyProps {
  config: OnboardingConfig;
}

export type FormDataProps = {
  updateDataCallback: (values: UserOnboardingData, final?: boolean) => void;
  className?: string;
  disableActions?: boolean;
  config: OnboardingConfig;
  formData: UserOnboardingData;
};

export const RegistrationSurvey = ({
  className,
  config,
}: RegistrationSurveyProps & WithClassName) => {
  const { t } = useTranslation('translation', { keyPrefix: 'survey' });
  const { t: tError } = useTranslation('translation', { keyPrefix: 'error' });
  const navigate = useNavigate();
  const { toast } = useToast();
  const queryClient = useQueryClient();

  const [formData, setFormData] = useState<UserOnboardingData>({});

  const { onboard, isPending } = useUserOnboarding();

  const steps = [
    { label: t('language.title'), component: LanguageSelectionForm },
    {
      label: t('residence.title'),
      component: ResidenceSelectionForm,
    },
    {
      label: t('childCare.title'),
      component: ChildCareSelectionForm,
      className: 'desktop:w-4/12',
    },
    {
      label: t('personal.title'),
      component: PersonalInformationForm,
    },
    {
      label: t('profession.title'),
      component: ProfessionSelectionForm,
    },
    {
      label: t('workEnv.title'),
      component: PrimaryWorkEnvironmentSelectionForm,
    },
  ];

  const displayToastError = (error: Error) => {
    toast({
      title: tError(error.name),
      description: tError(error.message ?? 'message.unknown'),
    });
  };

  const navigateHome = async () => {
    await queryClient.invalidateQueries({ queryKey: ['profile'] });
    await queryClient.prefetchQuery(userProfileQueryOptions);
    navigate({ to: '/', from: '/survey' });
  };

  const updateFormData = (values: UserOnboardingData, final?: boolean) => {
    const newFormData = { ...formData, ...values };
    if (!final) setFormData(newFormData);
    else {
      const submitData = mapDataToConfig(newFormData);
      onboard(submitData, {
        onError: displayToastError,
        onSuccess: navigateHome,
      });
    }
  };

  return (
    <div
      className={cn(
        'flex flex-col w-full h-full p-4 px-6 desktop:p-8',
        className,
      )}
    >
      <Stepper
        variant="circle-alt"
        initialStep={0}
        steps={steps}
        mobileBreakpoint={UIViewLayoutDictionary.desktopMinWidth}
      >
        {steps.map((stepProps) => (
          <Step key={stepProps.label} {...stepProps}>
            <stepProps.component
              updateDataCallback={updateFormData}
              className={cn(
                'w-11/12 max-w-[500px] pt-2 desktop:pt-20',
                stepProps.className,
              )}
              disableActions={isPending}
              config={config}
              formData={formData}
            />
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export const RegistrationSurveySkeleton = ({ className }: WithClassName) => (
  <div
    className={cn(
      'flex flex-row desktop:flex-col w-full h-full p-4 desktop:p-8 desktop:items-center',
      className,
    )}
  >
    <Skeleton className="h-3/4 w-12 desktop:w-11/12 desktop:h-32" />
    <div className="w-full h-full flex flex-col gap-8 desktop:items-center">
      <Skeleton className="h-10 w-full ml-4 desktop:hidden" />
      <Skeleton className="w-11/12 max-w-[500px] mobile:ml-4 desktop:mt-20 h-[40vh]" />
      <Skeleton className="h-10 w-full ml-4 desktop:hidden" />
      <Skeleton className="h-10 w-full ml-4 desktop:hidden" />
    </div>
  </div>
);
