import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from '../../ui/form';
import { useStepper } from '../../ui/stepper';
import { useTranslation } from 'react-i18next';
import { FormDataProps } from '../registration-survey';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../ui/select';
import { cn } from '@/lib/utils';
import { SurveyFormActions } from '../form-actions';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ClearLabeledInput } from '../../ui/input';
import { Specialty } from '@/lib/models';

const professionsWithoutSpecialties = [
  'Respiratory Therapist',
  'Student',
  'other',
];
const specialtiesWithOther = ['Resident', 'Fellow', 'other'];
const ProfessionSelectionFormSchema = z
  .object({
    profession: z.string().min(1, 'required'),
    profession_specialty: z.string(),
    custom_profession: z.string(),
    custom_profession_specialty: z.string(),
  })
  .refine(
    (data) =>
      (!professionsWithoutSpecialties.includes(data.profession) &&
        data.profession_specialty != '') ||
      professionsWithoutSpecialties.includes(data.profession),
    {
      message: 'required',
      path: ['profession_specialty'],
    },
  )
  .refine(
    (data) =>
      data.profession !== 'other' ||
      (data.profession === 'other' && data.custom_profession.length > 0),
    {
      message: 'required',
      path: ['custom_profession'],
    },
  )
  .refine(
    (data) =>
      professionsWithoutSpecialties.includes(data.profession) ||
      !specialtiesWithOther.includes(data.profession_specialty) ||
      (specialtiesWithOther.includes(data.profession_specialty) &&
        data.custom_profession_specialty !== ''),
    {
      message: 'required',
      path: ['custom_profession_specialty'],
    },
  );
type ProfessionSelectionFormData = z.infer<
  typeof ProfessionSelectionFormSchema
>;

interface WithFreeText {
  free_text_profession?: true;
}
type SpecialtyWithFreeText = Specialty & WithFreeText;

interface Profession {
  name: string;
  displayName?: string;
}
type ProfessionWithSpecialtiesWithFreeText = Profession &
  WithFreeText & {
    specialties: SpecialtyWithFreeText[];
  };

export const ProfessionSelectionForm = ({
  updateDataCallback,
  className,
  config,
  formData,
}: FormDataProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'survey' });
  const { t: tShared } = useTranslation('translation', { keyPrefix: 'shared' });
  const { t: tError } = useTranslation('translation', {
    keyPrefix: 'error.form',
  });
  const { nextStep } = useStepper();

  const other: ProfessionWithSpecialtiesWithFreeText = {
    name: 'other',
    displayName: tShared('other'),
    free_text_profession: true,
    specialties: [],
  };

  const [specialties, setSpecialties] = useState<SpecialtyWithFreeText[]>(
    config.professions.find(
      (profession) => formData.profession === profession.name,
    )?.specialties ?? [],
  );
  const [profession, setProfession] =
    useState<ProfessionWithSpecialtiesWithFreeText | null>(
      formData.profession === 'other'
        ? other
        : config.professions.find(
            (profession) => formData.profession === profession.name,
          ) ?? null,
    );
  const [hasFreeText, setHasFreeText] = useState(false);

  useEffect(() => {
    const foundProfession = professionData.find(
      (profession) => profession.name === formData.profession,
    )!;
    const specialty = specialties.find(
      (specialty) => specialty.name === formData.profession_specialty,
    )!;
    setHasFreeText(
      !!foundProfession?.free_text_profession ||
        !!specialty?.free_text_profession,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasInitialCustomSpecialty =
    formData.custom_profession_specialty != null &&
    formData.custom_profession_specialty !== '';
  const hasInitialSpecialty =
    formData.profession != 'other' &&
    formData.profession !== null &&
    formData.profession_specialty != null &&
    formData.profession_specialty !== '';

  const form = useForm<ProfessionSelectionFormData>({
    resolver: zodResolver(ProfessionSelectionFormSchema),
    defaultValues: {
      custom_profession: !hasInitialCustomSpecialty
        ? formData.custom_profession ?? ''
        : '',
      custom_profession_specialty: formData.custom_profession_specialty ?? '',
      profession_specialty: hasInitialSpecialty
        ? formData.profession_specialty ?? ''
        : '',
      profession: formData.profession ?? '',
    },
  });

  function onSubmit(data: ProfessionSelectionFormData) {
    updateDataCallback(data);
    nextStep();
  }

  const freeTextSpecialties: Record<
    string,
    { hasOther: boolean; values: string[] }
  > = {
    ['Physician']: { hasOther: true, values: ['Resident', 'Fellow'] },
  };

  const professionData: ProfessionWithSpecialtiesWithFreeText[] = useMemo(
    () => [...config.professions, other],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [config],
  );

  const onProfessionChange =
    (callback: (data: string) => void) => (data: string) => {
      callback(data);
      const foundProfession = professionData.find(
        (profession) => profession.name === data,
      )!;
      setProfession(foundProfession);
      setHasFreeText(!!foundProfession.free_text_profession);
      const professionSpecialties =
        foundProfession.specialties == null ||
        foundProfession.specialties.length < 1
          ? []
          : [...foundProfession.specialties];
      if (freeTextSpecialties[foundProfession.name]) {
        professionSpecialties.forEach((specialty) => {
          if (
            freeTextSpecialties[foundProfession.name].values.includes(
              specialty.name,
            )
          ) {
            specialty.free_text_profession = true;
          }
          return specialty;
        });
        if (freeTextSpecialties[foundProfession.name].hasOther) {
          professionSpecialties.push(other);
        }
      }
      setSpecialties(professionSpecialties);

      form.resetField('custom_profession', { defaultValue: '' });
      form.resetField('profession_specialty', { defaultValue: '' });
      form.resetField('custom_profession_specialty', { defaultValue: '' });

      const formData = form.getValues();
      updateDataCallback(formData);
    };

  const onSpecialtyChange = useCallback(
    (callback: (data: string) => void) => (data: string) => {
      callback(data);
      const specialty = specialties.find(
        (specialty) => specialty.name === data,
      )!;
      setHasFreeText(!!specialty.free_text_profession);
      form.resetField('custom_profession_specialty', { defaultValue: '' });

      const formData = form.getValues();
      updateDataCallback(formData);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [specialties],
  );

  const onInputChange =
    (callback: (data: string) => void) =>
    (event: React.FormEvent<HTMLInputElement>) => {
      callback(event.currentTarget.value);
      const formData = form.getValues();
      updateDataCallback(formData);
    };

  return (
    <div className="flex w-full justify-center">
      <div className={cn('flex flex-col gap-8', className)}>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
            <FormField
              control={form.control}
              name="profession"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('profession.profession.label')}</FormLabel>
                  <Select
                    onValueChange={onProfessionChange(field.onChange)}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue
                          placeholder={t('profession.profession.select')}
                        />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {professionData.map((profession) => (
                        <SelectItem
                          key={profession.name}
                          value={profession.name}
                        >
                          {profession.displayName ?? profession.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage translationFunction={tError} />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="profession_specialty"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('profession.specialty.label')}</FormLabel>
                  <Select
                    onValueChange={onSpecialtyChange(field.onChange)}
                    defaultValue={field.value}
                    disabled={!specialties.length}
                    value={field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue
                          placeholder={t('profession.specialty.select')}
                        />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {specialties.map((specialty) => (
                        <SelectItem key={specialty.name} value={specialty.name}>
                          {specialty.displayName ?? specialty.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage translationFunction={tError} />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name={'custom_profession'}
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <ClearLabeledInput
                      hidden={!hasFreeText || !profession?.free_text_profession}
                      id="custom_profession"
                      placeholder={t('profession.profession.label')}
                      {...field}
                      onChange={onInputChange(field.onChange)}
                    />
                  </FormControl>
                  <FormMessage translationFunction={tError} />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name={'custom_profession_specialty'}
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <ClearLabeledInput
                      hidden={!hasFreeText || profession?.free_text_profession}
                      id="custom_profession_specialty"
                      placeholder={t('profession.specialty.label')}
                      {...field}
                      onChange={onInputChange(field.onChange)}
                    />
                  </FormControl>
                  <FormMessage translationFunction={tError} />
                </FormItem>
              )}
            />

            <SurveyFormActions type="submit" />
          </form>
        </Form>
      </div>
    </div>
  );
};
