import {
  Card,
  CardDescription,
  CardHeader,
  CardImage,
  CardTitle,
} from '../ui/card';
import { Skeleton } from '../ui/skeleton';

export interface CardWithImageLayoutProps {
  title: string;
  image?: string;
  description: string;
  action?: () => void;
}

export const CardWithImageLayout = ({
  title,
  image,
  description,
  action,
}: CardWithImageLayoutProps) => {
  return (
    <Card
      onClick={action}
      className="hover:cursor-pointer flex flex-col w-11/12 lg:w-[47%] 2xl:w-[23%] shadow-md hover:shadow-xl overflow-hidden transition-shadow duration-300"
    >
      <CardImage src={image} className="my-1 desktop:aspect-video" />
      <CardHeader className="px-6 py-3">
        <CardTitle>{title}</CardTitle>
        <CardDescription>{description}</CardDescription>
      </CardHeader>
    </Card>
  );
};

export const CardImageLayoutSkeleton = () => (
  <Skeleton className="w-11/12 lg:w-[46%] 2xl:w-[23%] aspect-video" />
);
