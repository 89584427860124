import { ForgotPassword } from '@/components/auth/auth';
import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/forgot-password/reset')({
  component: ForgotPassword,
  beforeLoad: ({ context }) => {
    if (context.auth.isSignedIn) {
      throw redirect({
        to: '/',
        from: '/forgot-password/reset',
      });
    }
  },
});
