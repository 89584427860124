import { AuthState, useAuth } from '@/components/providers/auth';
import { Outlet } from '@tanstack/react-router';

import { createRootRouteWithContext } from '@tanstack/react-router';
import { QueryClient } from '@tanstack/react-query';
import { Suspense } from 'react';
import { SidebarState } from '@/components/providers/sidebar';
import Sidebar from '@/components/side-nav/sidebar';
import React from 'react';
import BreadCrumbNavigation from '@/components/breadcrumb-navigation/breadcrumb-navigation';
import { Toaster } from '@/components/ui/toaster';
import { cn } from '@/lib/utils';

const TanStackRouterDevtools =
  process.env.NODE_ENV === 'production'
    ? () => null // Render nothing in production
    : React.lazy(() =>
        // Lazy load in development
        import('@tanstack/router-devtools').then((res) => ({
          default: res.TanStackRouterDevtools,
          // For Embedded Mode
          // default: res.TanStackRouterDevtoolsPanel
        })),
      );

interface RouterContext {
  auth: AuthState;
  queryClient: QueryClient;
  sidebar: SidebarState;
}

export const Route = createRootRouteWithContext<RouterContext>()({
  component: RootComponent,
});

function RootComponent() {
  const { isSignedIn, requiresOnboarding } = useAuth();

  return (
    <div className="w-full flex flex-row ">
      {isSignedIn && (
        <Sidebar
          disableNavigation={requiresOnboarding}
          className="flex mobile:fixed z-20 mobile:rounded mobile:top-3 mobile:left-4"
        />
      )}
      <Toaster />
      <main className="w-full h-full flex flex-col">
        {isSignedIn && !requiresOnboarding && (
          <BreadCrumbNavigation
            className="w-full z-10 mobile:pt-5 pt-4 px-6 pb-2 mobile:pb-4 desktop:border-b mobile:pl-16 bg-white"
            itemsToDisplay={3}
          />
        )}
        <div
          className={cn('mobile:relative mobile:z-0', isSignedIn ? 'p-8' : '')}
        >
          <Outlet />
        </div>
      </main>
      <Suspense>
        <TanStackRouterDevtools />
      </Suspense>
    </div>
  );
}
