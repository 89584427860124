import {
  UserStats,
  UserStatsByProfession,
  UserStatsProperties,
} from '@/lib/queries/analytics/analytics-users';
import { AnalyticsStats, DataKeys, KeyType } from './analytics-stats';
import { CheckCircle, Circle, MinusCircle, Users } from 'lucide-react';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StackedBarChartData } from '../ui/charts/stacked-bar-chart';
import { OnboardingConfig } from '@/lib/models';
import { ChartConfig } from '../ui/chart';
import { useNavigate } from '@tanstack/react-router';

const metrics: readonly (keyof UserStatsProperties)[] = [
  'users_completed_media',
  'users_started_not_completed_media',
  'users_no_media',
] as const;
type MetricTypes = (typeof metrics)[number];

interface UsersAnalyticsProps {
  stats: {
    byProfession: UserStatsByProfession[];
    total: UserStats;
  };
  config: OnboardingConfig;
}

export const UsersAnalytics = ({
  config: { professions },
  stats,
}: UsersAnalyticsProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'analytics.userStats',
  });
  const { t: tShared } = useTranslation('translation', {
    keyPrefix: 'shared',
  });

  const [selectedProfession, setSelectedProfession] = useState<string>(
    professions[0].name,
  );
  const [selectedProfessions, setSelectedProfessions] = useState<string[]>([
    ...professions.map((profession) => profession.name),
    tShared('none'),
  ]);

  const navigate = useNavigate();

  const navigateAction = useCallback(
    (stat: string) => {
      return () => {
        navigate({
          to: '/analytics/users/table/$stat',
          params: {
            stat: stat,
          },
        });
      };
    },
    [navigate],
  );

  const metricsMap: Record<MetricTypes, DataKeys> = useMemo(
    () => ({
      users: {
        icon: Users,
        translationKey: 'users',
        action: () => navigate({ to: '/users' }),
      },
      users_completed_media: {
        icon: CheckCircle,
        translationKey: 'completed',
        action: navigateAction('users_completed_media'),
      },
      users_started_not_completed_media: {
        icon: Circle,
        translationKey: 'notCompleted',
        action: navigateAction('users_started_not_completed_media'),
      },
      users_no_media: {
        icon: MinusCircle,
        translationKey: 'notStarted',
        action: navigateAction('users_no_media'),
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigateAction],
  );

  const professionStrings = useMemo(() => {
    const allProfessions = professions.map((profession) => profession.name);
    allProfessions.push(tShared('none'));
    return allProfessions;
  }, [professions, tShared]);

  const totalData = useMemo(() => {
    return stats.total;
  }, [stats]);

  const data: StackedBarChartData[] = useMemo(() => {
    const professionsToSearch = selectedProfessions.map((profession) =>
      profession === tShared('none') ? null : profession,
    );
    const professionStats = stats.byProfession.filter((professionStats) =>
      professionsToSearch.includes(professionStats.profession),
    )!;
    return metrics.map((propertyKey) => {
      return {
        name: t(`${metricsMap[propertyKey].translationKey}.title`),
        data: professionStats.reduce((acc, selectedProfession) => {
          const profession =
            selectedProfession.profession == null
              ? tShared('none')
              : selectedProfession.profession;
          return {
            ...acc,
            [profession]: selectedProfession[propertyKey],
          };
        }, {}),
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProfessions, stats, tShared, t]);

  const keys: KeyType<{ [key: string]: string }, string>[] = useMemo(() => {
    return professionStrings.reduce(
      (acc, profession, index) => {
        const opacity = 1 - 0.7 * (index / 4) ** 0.8;
        acc.push({
          name: profession,
          label: profession,
          opacity: opacity,
        });
        return acc;
      },
      [] as {
        name: string;
        label: string;
        opacity: number;
      }[],
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const config = useMemo(() => {
    const selectedKeys = keys.filter((key) =>
      selectedProfessions.includes(key.name),
    );

    return selectedKeys.reduce(
      (acc, curr) => ({
        ...acc,
        [`data.${curr.name}`]: {
          label: curr.label,
          color: `hsl(var(--chart-bar-color) / ${curr.opacity})`,
        },
      }),
      {} as ChartConfig,
    );
  }, [selectedProfessions, keys]);

  const mappedProfessions = useMemo(() => {
    const mappedProfessions = professions.map((profession) => ({
      label: profession.name,
      value: profession.name,
    }));
    mappedProfessions.push({
      label: tShared('none'),
      value: tShared('none'),
    });

    return mappedProfessions;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  return (
    <AnalyticsStats
      groupingDisabled
      groupingSelection={selectedProfession}
      setGroupingSelection={setSelectedProfession}
      translate={t}
      totalOptions={professionStrings}
      groupingsMap={metricsMap}
      totalData={totalData}
      data={data}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      metricOptions={mappedProfessions as any}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      metrics={selectedProfessions as any}
      setMetrics={setSelectedProfessions}
      config={config}
    />
  );
};
