import { useTranslation } from 'react-i18next';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../ui/tabs';
import LoginCard from './login/login.card';
import RegisterCard from './register/register.card';
import logo from '../../assets/CareWays-logo.png';
import { cn } from '@/lib/utils';
import { WithClassName } from '@/types/with-classname';

export const AuthTabs = ({ className }: WithClassName) => {
  const { t } = useTranslation('translation', { keyPrefix: 'auth' });
  return (
    <div
      className={cn(
        'flex flex-col w-full h-full items-center gap-2',
        className,
      )}
    >
      <img className="max-h-[50vh] aspect-video" src={logo} />
      <div className="w-full">
        <Tabs defaultValue="singIn" className="w-full h-full">
          <TabsList className="grid w-full grid-cols-2">
            <TabsTrigger value="singIn">{t('login')}</TabsTrigger>
            <TabsTrigger value="signUp">{t('register')}</TabsTrigger>
          </TabsList>
          <TabsContent value="singIn">
            <LoginCard></LoginCard>
          </TabsContent>
          <TabsContent value="signUp">
            <RegisterCard></RegisterCard>
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
};
