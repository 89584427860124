import { useTranslation } from 'react-i18next';
import { Tabs, TabsList, TabsTrigger } from '../ui/tabs';
import { Link, Outlet } from '@tanstack/react-router';
import { Separator } from '../ui/separator';

const accountPages = ['profile', 'preferences'] as const;
export type AccountPage = (typeof accountPages)[number];

export type AccountSettingsProps = {
  tab: AccountPage;
};

export function AccountSettings({ tab }: AccountSettingsProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'account' });
  return (
    <div className="flex flex-col gap-4">
      <Tabs value={tab} className="w-full">
        <TabsList className="grid grid-cols-2 w-fit">
          {accountPages.map((key) => (
            <TabsTrigger key={key} className="px-8" value={key} asChild>
              <Link preload={false} from="/account" to={`${key}`}>
                {t(`${key}.label`)}
              </Link>
            </TabsTrigger>
          ))}
        </TabsList>
      </Tabs>
      <div className="flex flex-col gap-4 w-full sm:max-w-lg">
        <div>
          <h2 className="text-sm text-muted-foreground">
            {t(`${tab}.description`)}
          </h2>
        </div>
        <Separator />
        <Outlet />
      </div>
    </div>
  );
}
