import { flexRender, Table as TableType } from '@tanstack/react-table';
import { t } from 'i18next';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../ui/table';
import { Skeleton } from '../ui/skeleton';
import { ChangeEvent } from 'react';
import { Input } from '../ui/input';
import { Button } from '../ui/button';
import { useTranslation } from 'react-i18next';

type SearchBarProps = {
  placeholder: string;
  key: string;
  getFilterValue: (key: string) => string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};
type WithSearch = { search: SearchBarProps };
type TableComponentProps<T> =
  | { table: TableType<T> }
  | ({ table: TableType<T> } & WithSearch);

function hasSearchBarProps<T>(
  props: TableComponentProps<T>,
): props is { table: TableType<T> } & WithSearch {
  return !!(props as WithSearch).search;
}

export function TableComponent<T>(props: TableComponentProps<T>) {
  const { t: tShared } = useTranslation('translation', { keyPrefix: 'shared' });

  return (
    <div className="w-full">
      {hasSearchBarProps(props) && (
        <div className="flex items-center py-4 gap-2 flex-wrap">
          <Input
            placeholder={props.search.placeholder}
            value={props.search.getFilterValue(props.search.key)}
            onChange={props.search.onChange}
            className="max-w-xs"
          />
        </div>
      )}
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {props.table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      key={header.id}
                      style={{ width: `${header.getSize()}px` }}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {props.table.getRowModel().rows?.length ? (
              props.table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={props.table._getColumnDefs().length}
                  className="h-24 text-center"
                >
                  {t('noResults')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex items-center justify-end space-x-2 pt-4">
        <div className="space-x-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => props.table.previousPage()}
            disabled={!props.table.getCanPreviousPage()}
          >
            {tShared('prev')}
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={() => props.table.nextPage()}
            disabled={!props.table.getCanNextPage()}
          >
            {tShared('next')}
          </Button>
        </div>
      </div>
    </div>
  );
}

export const TableComponentSkeleton = () => {
  return (
    <div className="rounded-md border">
      <Skeleton className="w-full h-[70vh]" />
    </div>
  );
};
