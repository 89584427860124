import { useNavigate } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import { cn } from '@/lib/utils';
import { ClearLabeledInput } from '../../ui/input';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { parseAuthError, signUpWithEmailAndPass } from '@/lib/firebase';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '../../ui/form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '../../ui/button';
import { toast } from '@/components/ui/use-toast';
import { WithClassName } from '@/types/with-classname';

const formSchema = z
  .object({
    email: z.string().email(),
    password: z.string().min(1).max(50),
    confirmPassword: z.string().min(1).max(50),
  })
  .required()
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords don't match",
    path: ['confirmPassword'],
  });

type RegisterFormData = z.infer<typeof formSchema>;

export default function RegisterForm({ className }: WithClassName) {
  const { t } = useTranslation('translation', { keyPrefix: 'auth' });
  const { t: tShared } = useTranslation('translation', { keyPrefix: 'shared' });
  const { t: tError } = useTranslation('translation', { keyPrefix: 'error' });

  const navigate = useNavigate({ from: '/auth' });

  const form = useForm<RegisterFormData>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
      password: '',
      confirmPassword: '',
    },
  });

  const registerWithEmailAndPassword = async (
    email: string,
    password: string,
  ) => {
    form.clearErrors();
    try {
      await signUpWithEmailAndPass(email, password);
      navigate({ from: '/auth', to: '/' });
    } catch (error) {
      const message = parseAuthError(error);

      toast({
        title: tError('type.registerError'),
        description: tError(message ?? 'message.unknown'),
      });
    }
  };

  const onSubmit = (values: RegisterFormData) => {
    registerWithEmailAndPassword(values.email, values.password);
  };

  return (
    <Form {...form}>
      <form
        className={cn('flex flex-col justify-center gap-8 pt-2', className)}
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <ClearLabeledInput
                  autoComplete="email"
                  id="userEmail"
                  type="email"
                  placeholder={tShared('email')}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <ClearLabeledInput
                  autoComplete="new-password"
                  id="password"
                  type="password"
                  placeholder={t('password')}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="confirmPassword"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <ClearLabeledInput
                  autoComplete="new-password"
                  id="confirmPassword"
                  type="password"
                  placeholder={t('confirmPassword')}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button type="submit">{t('register')}</Button>
      </form>
    </Form>
  );
}
