import { Separator } from '../ui/separator';
import { Link } from '@tanstack/react-router';
import { Check, Pause, Play } from 'lucide-react';
import { useCallback } from 'react';
import { cn } from '@/lib/utils';
import { UIViewLayoutDictionary } from '@/constants/view-layout';
import { useMediaQuery } from '@react-hook/media-query';
import { Chapter, Module } from '@/lib/models';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '../ui/skeleton';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../ui/tooltip';

export interface ChapterListProps {
  module: Module;
  chapters: Chapter[];
  currentChapterId: number;
}

export const ChapterList = ({
  module,
  chapters,
  currentChapterId,
}: ChapterListProps) => {
  const isDesktop = useMediaQuery(
    `(min-width: ${UIViewLayoutDictionary.desktopMinWidth})`,
  );
  const { t } = useTranslation('translation', { keyPrefix: 'course' });

  const isCurrentChapter = useCallback(
    (otherChapter: Chapter) => {
      return otherChapter.id === currentChapterId;
    },
    [currentChapterId],
  );

  return (
    <div className="h-full flex flex-col gap-3">
      {isDesktop && (
        <>
          <div className="px-2">
            <h3 className="text-xl xl:text-2xl font-semibold leading-none tracking-tight">
              {module.name}
            </h3>
            <div className="h-20 text-sm xl:text-base xl:h-28 flex items-center w-full text-muted-foreground">
              {module.description}
            </div>
          </div>
          <Separator orientation="horizontal" />
        </>
      )}
      <div className="h-full w-full flex flex-col">
        <h3 className="mb-4 text-xl xl:text-2xl font-semibold px-2 leading-none">
          {t('moduleChapters')}
        </h3>
        <div className="flex flex-col gap-2">
          {chapters.map((chapter, index) => (
            <TooltipProvider key={chapter.id}>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Link
                    params
                    from="/course/$courseId/modules/$moduleId"
                    to="/courses/$courseId/modules/$moduleId"
                    disabled={isCurrentChapter(chapter)}
                    search={{ chapter: index }}
                    className={cn(
                      'text-base xl:text-lg font-semibold flex flex-row gap-2 p-2 rounded',
                      isCurrentChapter(chapter)
                        ? 'bg-muted hover:cursor-default'
                        : 'hover:bg-muted',
                    )}
                  >
                    {isCurrentChapter(chapter) ? (
                      <Pause className="self-center" />
                    ) : chapter.completion > 0.85 ? (
                      <Check className="self-center" color="green" />
                    ) : (
                      <Play className="self-center" />
                    )}
                    <span className="overflow-ellipsis w-full desktop:whitespace-nowrap overflow-hidden">
                      {chapter.name}
                    </span>
                  </Link>
                </TooltipTrigger>
                <TooltipContent>
                  <p>{chapter.name}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          ))}
        </div>
      </div>
    </div>
  );
};

export const ChapterListSkeleton = () => {
  return (
    <div className="h-full flex flex-col gap-3">
      <Skeleton className="h-12 w-full" />
      <Skeleton className="h-20 w-full" />
      <Separator orientation="horizontal" />

      <div className="h-full w-full flex flex-col gap-4">
        {Array(3)
          .fill(0)
          .map((_, idx) => (
            <Skeleton key={idx} className="h-12 p-2 rounded w-full" />
          ))}
      </div>
    </div>
  );
};
