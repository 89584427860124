import { ModuleFully } from '@/lib/queries/analytics/analytics-course';
import {
  ExpandedState,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { useState } from 'react';
import { coursesAnalyticsColumns } from './courses-analytics-table-columns';
import { TableComponent } from '@/components/table/table-component';
import { useTranslation } from 'react-i18next';

interface CoursesAnalyticsTableProps {
  modules: ModuleFully[];
}

export const CoursesAnalyticsTable = ({
  modules,
}: CoursesAnalyticsTableProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'analytics.table.courses',
  });

  const [sorting, setSorting] = useState<SortingState>([]);
  const [expanded, setExpanded] = useState<ExpandedState>({});

  const table = useReactTable({
    data: modules,
    columns: coursesAnalyticsColumns,
    onSortingChange: setSorting,
    state: {
      sorting,
      expanded,
    },
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.chapters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    autoResetPageIndex: false,
    filterFromLeafRows: true,
  });

  const onInputFilterChange = (key: string, value: string) => {
    table.getColumn(key)?.setFilterValue(value);
  };

  const getFilterValue = (key: string): string => {
    return `${table.getColumn(key)?.getFilterValue() ?? ''}`;
  };

  return (
    <TableComponent
      table={table}
      search={{
        onChange: (event) => onInputFilterChange('name', event.target.value),
        placeholder: t('name'),
        key: 'name',
        getFilterValue: getFilterValue,
      }}
    />
  );
};
