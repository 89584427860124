import {
  CourseCard,
  CourseCardListSkeleton,
} from '@/components/course/course-card';
import { DefaultItemsLayout } from '@/components/layouts/default-items-layout';
import { AxiosErrorComponent } from '@/components/ui/errors/axios-error';
import { Course } from '@/lib/models';
import { coursesQueryOptions } from '@/lib/queries/course';
import { useSuspenseQuery } from '@tanstack/react-query';
import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/courses/')({
  component: Courses,
  beforeLoad: ({ context: { auth } }) => {
    if (!auth.isSignedIn) {
      throw redirect({
        to: '/auth',
        from: '/courses',
        search: {
          redirect: '/courses',
        },
      });
    }
  },
  loader: ({ context: { queryClient } }) =>
    queryClient.ensureQueryData(coursesQueryOptions),
  errorComponent: AxiosErrorComponent,
  pendingComponent: CourseCardListSkeleton,
});

function Courses() {
  const {
    data: { courses },
  } = useSuspenseQuery(coursesQueryOptions);

  const navigate = Route.useNavigate();
  const onSelectCourse = (course: Course) => {
    navigate({
      from: '/courses',
      to: '/courses/$courseId/modules',
      params: { courseId: course.id.toString() },
    });
  };

  return (
    <DefaultItemsLayout>
      {courses.map((course) => (
        <CourseCard
          key={course.id}
          course={course}
          onClick={() => onSelectCourse(course)}
        />
      ))}
    </DefaultItemsLayout>
  );
}
