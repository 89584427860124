import { Skeleton } from '../ui/skeleton';
import ChapterContents, { ChapterContentsSkeleton } from './chapter-content';
import { ChapterList, ChapterListSkeleton } from './chapter-list';
import { Chapter, ChapterWithContent, Module } from '@/lib/models';

interface CourseModuleProps {
  chapters: Chapter[];
  chapter: ChapterWithContent;
  module: Module;
}

export const CourseModule = ({
  chapter,
  chapters,
  module,
}: CourseModuleProps) => {
  return (
    <div className="flex flex-col desktop:flex-row gap-8 mobile:w-[calc(100vw-32px*2)]">
      <div className="flex flex-col gap-4 desktop:gap-8 mobile:items-middle flex-grow">
        <div className="flex flex-col gap-4 desktop:gap-8">
          <h3 className="text-3xl xl:text-6xl font-semibold leading-none tracking-tight">
            {chapter.name}
          </h3>
          <span className="text-sm xl:text-xl leading-none tracking-tight text-muted-foreground">
            {chapter.description}
          </span>
        </div>
        <ChapterContents key={chapter.id} content={chapter.content} />
      </div>
      <div className="w-full xl:w-80 mt-3 desktop:ml-auto max-w-fit desktop:max-w-[300px]">
        <ChapterList
          module={module}
          chapters={chapters}
          currentChapterId={chapter.id}
        />
      </div>
    </div>
  );
};

export const CourseModuleSkeleton = () => {
  return (
    <div className="flex flex-col desktop:flex-row gap-8">
      <div className="flex flex-col gap-4 desktop:gap-8 mobile:items-middle w-3/4">
        <div className="flex flex-col gap-4 desktop:gap-8">
          <Skeleton className="h-16 w-60" />
          <Skeleton className="h-12 w-80" />
        </div>
        <ChapterContentsSkeleton />
      </div>
      <div className="min-w-fit w-2/5 desktop:w-60 xl:w-80 mt-3 xl:mt-8 desktop:ml-auto">
        <ChapterListSkeleton />
      </div>
    </div>
  );
};
