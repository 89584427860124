import { queryOptions } from '@tanstack/react-query';
import client, { APIResponse } from './client';
import { Course } from '../models';

export type CoursesResponse = {
  courses: Course[];
};

export const coursesQueryOptions = queryOptions({
  queryKey: ['courses'],
  queryFn: () => fetchCourses(),
});

async function fetchCourses(): Promise<CoursesResponse> {
  const response =
    await client.get<APIResponse<CoursesResponse>>('/courses/me');
  return response.data.data;
}

export type CourseResponse = {
  course: Course;
};

export function courseQueryOptions(courseId: string) {
  return queryOptions({
    queryKey: ['course', courseId],
    queryFn: () => fetchCourseById(courseId),
  });
}

async function fetchCourseById(courseId: string): Promise<CourseResponse> {
  const response = await client.get<APIResponse<CourseResponse>>(
    `/courses/${courseId}`,
  );
  return response.data.data;
}
