import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select';
import {
  CardWithTextLayout,
  CardWithTextLayoutSkeleton,
} from '../layouts/card-with-text-layout';
import { DefaultItemsLayout } from '../layouts/default-items-layout';
import { Card, CardContent } from '../ui/card';
import { BarChartComponentSkeleton } from '../ui/charts/bar-chart';
import { LucideIcon } from 'lucide-react';
import { Label } from '../ui/label';
import { Skeleton } from '../ui/skeleton';
import {
  StackedBarChartComponent,
  StackedBarChartData,
} from '../ui/charts/stacked-bar-chart';
import { MultiSelect } from '../ui/multi-select';
import { ChartConfig } from '../ui/chart';

export type DataKeys = {
  translationKey: string;
  icon: LucideIcon;
  action?: () => void;
};

type PartialRecord<T, K extends keyof T, V> = Record<K, V>;

interface GroupingProps {
  groupingSelection: string;
  setGroupingSelection: (value: string) => void;
  totalOptions: string[];
}

export type KeyType<T, K extends keyof T> = {
  name: K;
  label: string;
  opacity: number;
};

interface BaseProps<T, K extends keyof T> {
  config: ChartConfig;
  totalData: T;
  groupingsMap: PartialRecord<T, K, DataKeys>;
  data: StackedBarChartData[];
  translate: (value: string) => string;
  setMetrics: (value: Extract<K, string>[]) => void;
  metrics: Extract<K, string>[];
  metricOptions: { label: string; value: Extract<K, string> }[];
}

type BasePropsGrouping<T, K extends keyof T> = BaseProps<T, K> & {
  groupingDisabled: true;
} & GroupingProps;

type BasePropsNoGrouping<T, K extends keyof T> = BaseProps<T, K> & {
  groupingDisabled?: false;
};

type AnalyticsStatsProps<T, K extends keyof T> =
  | BasePropsGrouping<T, K>
  | BasePropsNoGrouping<T, K>;

function isGroupingDisabled<T, K extends keyof T>(
  props: AnalyticsStatsProps<T, K>,
): props is BasePropsNoGrouping<T, K> {
  return !!props.groupingDisabled;
}

export function AnalyticsStats<T, K extends keyof T>(
  props: AnalyticsStatsProps<T, K>,
) {
  return (
    <div className="flex flex-col gap-4">
      {!isGroupingDisabled(props) && (
        <GroupingSelectionComponent
          setGroupingSelection={props.setGroupingSelection}
          groupingSelection={props.groupingSelection}
          translate={props.translate}
          totalOptions={props.totalOptions}
        />
      )}
      <DefaultItemsLayout
        noWrap
        className="justify-center sm:justify-normal pb-1"
      >
        {Object.keys(props.groupingsMap).map((valueKey) => {
          const key = valueKey as K;
          return (
            <CardWithTextLayout
              key={valueKey}
              title={props.translate(
                `${props.groupingsMap[key].translationKey}.title`,
              )}
              description={`${props.totalData![key]}`}
              icon={props.groupingsMap[key].icon}
              subDescription={props.translate(
                `${props.groupingsMap[key].translationKey}.description`,
              )}
              action={props.groupingsMap[key].action}
              className="max-w-full sm:max-w-[300px]"
            />
          );
        })}
      </DefaultItemsLayout>
      <div className="flex flex-col gap-1">
        <Label htmlFor="stat">{props.translate('stat')}</Label>
        <MultiSelect
          id="stat"
          options={props.metricOptions}
          onValueChange={(values) =>
            props.setMetrics(values as Extract<K, string>[])
          }
          defaultValue={props.metrics}
          placeholder={props.translate('metrics.placeholder')}
          variant="inverted"
          animation={2}
          maxCount={3}
        />
      </div>
      <Card className="col-span-4">
        <CardContent className="px-2 py-4">
          <StackedBarChartComponent
            config={props.config}
            className="h-[390px]"
            data={props.data}
            xLabel={props.translate('xAxis')}
            yLabel={props.translate('yAxis')}
          ></StackedBarChartComponent>
        </CardContent>
      </Card>
    </div>
  );
}

function GroupingSelectionComponent({
  setGroupingSelection,
  groupingSelection,
  translate,
  totalOptions,
}: GroupingProps & { translate: (value: string) => string }) {
  return (
    <Select
      onValueChange={setGroupingSelection}
      defaultValue={groupingSelection}
    >
      <div className="flex flex-col gap-1">
        <Label htmlFor="totalSelect">{translate('groupingLabel')}</Label>
        <SelectTrigger id="totalSelect" className="w-60">
          <SelectValue />
        </SelectTrigger>
      </div>

      <SelectContent>
        {totalOptions.map((option) => (
          <SelectItem key={option} value={option}>
            {option}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}

interface AnalyticsStatsSkeletonProps {
  noMetric?: boolean;
  numberOfCards?: number;
}

export const AnalyticsStatsSkeleton = ({
  noMetric,
  numberOfCards,
}: AnalyticsStatsSkeletonProps) => {
  return (
    <div className="flex flex-col gap-4">
      {!noMetric && <Skeleton className="h-10 w-60" />}

      <DefaultItemsLayout noWrap className="justify-center sm:justify-normal">
        {Array(numberOfCards ?? 3)
          .fill(0)
          .map((_, idx) => (
            <CardWithTextLayoutSkeleton key={idx} />
          ))}
      </DefaultItemsLayout>
      <Skeleton className="h-10 w-60" />

      <BarChartComponentSkeleton />
    </div>
  );
};
