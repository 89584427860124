import { UsersTable, UsersTableSkeleton } from '@/components/users/user-table';
import { usersQueryOptions } from '@/lib/queries/users';
import { useSuspenseQuery } from '@tanstack/react-query';
import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/users')({
  component: Users,
  beforeLoad: ({ context }) => {
    if (!context.auth.isAdmin) {
      throw redirect({
        to: '/',
        from: '/users',
      });
    }
  },
  loader: ({ context: { queryClient } }) =>
    queryClient.ensureQueryData(usersQueryOptions),
  pendingComponent: UsersSkeleton,
});

function Users() {
  const {
    data: { users },
  } = useSuspenseQuery(usersQueryOptions);

  return <UsersTable users={users} />;
}

function UsersSkeleton() {
  return <UsersTableSkeleton />;
}
