import { timestamp, uuid } from './utils';

export type ContentType = 'text' | 'media';
export type MediaType = 'video' | 'audio' | 'image';

export const USER_ROLES = ['user', 'admin'] as const;
type RolesTuple = typeof USER_ROLES;
export type UserRole = RolesTuple[number];

export const USER_STATUS = ['pending', 'accepted'] as const;
type StatusTuple = typeof USER_STATUS;
export type UserStatus = StatusTuple[number];

export interface Course {
  id: number;
  name: string;
  description: string;
  image: string;
}

export interface Module {
  id: number;
  name: string;
  description: string;
  image?: string;
}

export interface Chapter {
  id: number;
  name: string;
  description: string;
  completion: number;
}

export interface Media {
  id: number;
  type: MediaType;
  name: string;
  path: string;
  latest_playtime_percentage: number;
  transcript: string | null;
  url: string | null;
}

export interface ContentWithMedia {
  id: number;
  type: ContentType;
  media: Media | null;
  text: string | null;
}

export interface ChapterWithContent extends Chapter {
  content: ContentWithMedia[];
  completion: number;
}

export interface Specialty {
  name: string;
  displayName?: string;
}

export interface ProfessionWithSpecialties {
  name: string;
  displayName?: string;
  specialties: Specialty[];
}

export interface WorkEnvironment {
  name: string;
  displayName?: string;
}

export interface OnboardingConfig {
  professions: ProfessionWithSpecialties[];
  work_environments: WorkEnvironment[];
  preferred_languages: string[];
  age_ranges: string[];
}

export interface Residence {
  country: string;
  state: string | null;
  city: string | null;
}

export type MappedUser = Partial<User> & { status: UserStatus };

export interface User {
  id: number;
  email: string;
  first_name: string | null;
  last_name: string | null;
  role: UserRole | null;
}

export interface InvitedUser {
  email: string;
  role: UserRole | null;
}

export const SupportedLanguages = ['en', 'es'] as const;

export type Language = (typeof SupportedLanguages)[number];

export interface UserProfile {
  id: number;
  first_name: string | undefined;
  last_name: string | undefined;
  role: UserRole | undefined;
  onboarding_complete: boolean;
  preferred_language: Language | undefined;
}

export type Country = {
  id: number;
  name: string;
  code: string;
};

export type State = {
  id: number;
  name: string;
  code: string;
};

export type UserEventName =
  | 'sign_in'
  | 'video_start'
  | 'video_play'
  | 'video_pause'
  | 'video_seek'
  | 'video_progress'
  | 'video_duration';

export interface UserEvent<T = object> {
  id: string;
  name: UserEventName;
  timestamp: number;
  params: T;
}

export type UserEvents = UserEvent[];

export type AuthProvider = 'credentials' | 'google';
export interface SignInEvent extends UserEvent {
  name: 'sign_in';
  params: {
    provider: AuthProvider;
  };
}

export interface VideoStartEvent extends UserEvent {
  name: 'video_start';
  params: {
    video_id: number;
  };
}

export interface VideoPlayEvent extends UserEvent {
  name: 'video_play';
  params: {
    video_id: number;
  };
}

export interface VideoPauseEvent extends UserEvent {
  name: 'video_pause';
  params: {
    video_id: number;
  };
}

export interface VideoSeekEvent extends UserEvent {
  name: 'video_seek';
  params: {
    video_id: number;
    position: number;
  };
}

export interface VideoProgressEvent extends UserEvent {
  name: 'video_progress';
  params: {
    video_id: number;
    played: number;
    played_seconds: number;
  };
}

export interface VideoDurationEvent extends UserEvent {
  name: 'video_duration';
  params: {
    video_id: number;
    duration: number;
  };
}

// Note: Thhis could be sent from the backend but we are using this event as a test.
export function buildSignInEvent(provider: AuthProvider): SignInEvent {
  return {
    id: uuid(),
    name: 'sign_in',
    timestamp: timestamp(),
    params: {
      provider,
    },
  };
}

export function buildVideoStartEvent(mediaId: number): VideoStartEvent {
  return {
    id: uuid(),
    name: 'video_start',
    timestamp: timestamp(),
    params: {
      video_id: mediaId,
    },
  };
}

export function buildVideoPlayEvent(mediaId: number): VideoPlayEvent {
  return {
    id: uuid(),
    name: 'video_play',
    timestamp: timestamp(),
    params: {
      video_id: mediaId,
    },
  };
}

export function buildVideoPauseEvent(mediaId: number): VideoPauseEvent {
  return {
    id: uuid(),
    name: 'video_pause',
    timestamp: timestamp(),
    params: {
      video_id: mediaId,
    },
  };
}

export function buildVideoSeekEvent(
  mediaId: number,
  position: number,
): VideoSeekEvent {
  return {
    id: uuid(),
    name: 'video_seek',
    timestamp: timestamp(),
    params: {
      video_id: mediaId,
      position,
    },
  };
}

export function buildVideoProgressEvent(
  mediaId: number,
  played: number,
  playedSeconds: number,
): VideoProgressEvent {
  return {
    id: uuid(),
    name: 'video_progress',
    timestamp: timestamp(),
    params: {
      video_id: mediaId,
      played,
      played_seconds: playedSeconds,
    },
  };
}

export function buildVideoDurationEvent(
  mediaId: number,
  duration: number,
): VideoDurationEvent {
  return {
    id: uuid(),
    name: 'video_duration',
    timestamp: timestamp(),
    params: {
      video_id: mediaId,
      duration,
    },
  };
}
