import { USER_ROLES, USER_STATUS } from '@/lib/models';
import { ChevronDown, Edit, Plus, X } from 'lucide-react';
import { Button } from '../ui/button';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '../ui/skeleton';
import { cn } from '@/lib/utils';
import { Input } from '../ui/input';

interface UserTableTopActionsProps {
  getFilterValue: (key: string) => string;
  onFilterChange: (key: string, value: string) => void;
  onResetClick: () => void;
  onAddClick: () => void;
  onEditClick: () => void;
  onDeleteClick: () => void;
  onInputFilterChange: (key: string, value: string) => void;
  deleteDisabled: boolean;
  editDisabled: boolean;
}

export const UserTableTopActions = ({
  getFilterValue,
  onFilterChange,
  onResetClick,
  onAddClick,
  onEditClick,
  onDeleteClick,
  deleteDisabled,
  editDisabled,
  onInputFilterChange,
}: UserTableTopActionsProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'users' });
  const { t: tShared } = useTranslation('translation', { keyPrefix: 'shared' });

  const onActionFilterChange = (key: string, value: string) => () => {
    onFilterChange(key, value);
  };

  return (
    <>
      <Input
        placeholder={tShared('email')}
        value={getFilterValue('email')}
        onChange={(event) => onInputFilterChange('email', event.target.value)}
        className="max-w-xs"
      />
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="outline">
            {t('roleFilterText')} <ChevronDown className="ml-2 h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          {USER_ROLES.map((role) => {
            return (
              <DropdownMenuCheckboxItem
                key={role}
                className="capitalize"
                checked={getFilterValue('role') === role}
                onCheckedChange={onActionFilterChange('role', role)}
              >
                {role}
              </DropdownMenuCheckboxItem>
            );
          })}
        </DropdownMenuContent>
      </DropdownMenu>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="outline">
            {t('statusFilterText')} <ChevronDown className="ml-2 h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          {USER_STATUS.map((status) => {
            return (
              <DropdownMenuCheckboxItem
                key={status}
                className="capitalize"
                checked={getFilterValue('status') === status}
                onCheckedChange={onActionFilterChange('status', status)}
              >
                {status}
              </DropdownMenuCheckboxItem>
            );
          })}
        </DropdownMenuContent>
      </DropdownMenu>
      <Button variant="ghost" onClick={onResetClick}>
        {tShared('reset')}
      </Button>
      <Button
        variant="outline"
        className="desktop:ml-auto"
        onClick={onDeleteClick}
        disabled={deleteDisabled}
      >
        <X />
      </Button>
      <Button
        variant="outline"
        onClick={() => {
          onEditClick();
        }}
        disabled={editDisabled}
      >
        <Edit />
      </Button>
      <Button variant="outline" onClick={onAddClick}>
        <Plus />
      </Button>
    </>
  );
};

export const UserTableTopActionsSkeleton = () => {
  return Array(5)
    .fill(0)
    .map((_, index) => (
      <Skeleton
        key={index}
        className={cn('h-10 w-20', index === 3 ? 'desktop:ml-auto' : '')}
      />
    ));
};
