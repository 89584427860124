import { useSidebar } from '../providers/sidebar';
import { cn } from '@/lib/utils';
import { NavList } from './nav-list';
import { AccountNavItems, NavItems } from '@/constants/side-nav-items';
import { ArrowLeft, LogOut, MenuIcon, User } from 'lucide-react';
import { useNavigate } from '@tanstack/react-router';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu';
import { useAuth } from '../providers/auth';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { buttonVariants } from '../ui/button';
import { SheetTrigger, SheetContent, Sheet } from '../ui/sheet';
import { UIViewLayoutDictionary } from '@/constants/view-layout';
import { useMediaQuery } from '@react-hook/media-query';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '../ui/accordion';
import { useTranslation } from 'react-i18next';
import { WithClassName } from '@/types/with-classname';

export type SidebarProps = {
  disableNavigation: boolean;
} & WithClassName;

const DesktopSidebar = ({ disableNavigation, className }: SidebarProps) => {
  const { isOpen, toggle } = useSidebar();
  const { signOut, user, isAdmin } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation('translation', { keyPrefix: 'navigation' });

  const navItems = useMemo(() => {
    if (!isAdmin) {
      return NavItems.filter((item) => !item.admin);
    }
    return NavItems;
  }, [isAdmin]);

  const handleSignOut = () => {
    signOut();
    navigate({ from: '/', to: '/auth' });
  };

  return (
    <nav
      className={cn(
        `sticky top-0 hidden h-screen border-r md:block`,
        isOpen ? 'w-72' : 'w-[78px]',
        className,
      )}
    >
      <ArrowLeft
        className={cn(
          'absolute -right-3 top-3 cursor-pointer rounded-full border bg-background text-3xl text-foreground',
          !isOpen && 'rotate-180',
        )}
        onClick={toggle}
      />
      <div className="py-4 flex flex-col h-full ">
        <div className="px-3 py-2">
          <div className="space-y-1">
            <NavList
              className="text-background opacity-0 transition-all duration-300 group-hover:z-50 group-hover:ml-4 group-hover:rounded group-hover:bg-foreground group-hover:p-2 group-hover:opacity-100"
              items={navItems}
              disabled={disableNavigation}
            />
          </div>
        </div>
        <div className="px-3 py-2 mt-auto whitespace-nowrap">
          <DropdownMenu>
            <DropdownMenuTrigger className="flex flex-row gap-3 h-full w-full px-4 py-2 text-base font-medium !outline-none hover:bg-muted hover:no-underline hover:rounded">
              <User className="h-5 w-5" />
              {isOpen ? user?.displayName ?? t('myAccount') : null}
            </DropdownMenuTrigger>
            <DropdownMenuContent side="right" align="end" className="w-44">
              <DropdownMenuGroup>
                {AccountNavItems.map((item) => (
                  <DropdownMenuItem
                    key={item.title}
                    onClick={() => navigate({ to: item.href })}
                    className="flex h-12 justify-start gap-x-3"
                  >
                    <item.icon className="h-5 w-5" />
                    <div className="absolute left-12 text-base duration-200">
                      {t(item.title)}
                    </div>
                  </DropdownMenuItem>
                ))}
                <DropdownMenuItem
                  key="signOut"
                  onClick={handleSignOut}
                  className="flex h-12 justify-start gap-x-3"
                >
                  <LogOut className="h-5 w-5" />
                  <div className="absolute left-12 text-base duration-200">
                    {t('signOut')}
                  </div>
                </DropdownMenuItem>
              </DropdownMenuGroup>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
    </nav>
  );
};

export const MobileSidebar = ({
  disableNavigation,
  className,
}: SidebarProps) => {
  const { signOut, user } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation('translation', { keyPrefix: 'navigation' });

  const handleSignOut = () => {
    signOut();
    setIsOpen(false);

    navigate({ from: '/', to: '/auth' });
  };

  const { isOpen, toggle, setIsOpen } = useSidebar();
  const [isAccountOpen, setIsAccountOpen] = useState(false);

  useEffect(() => {
    if (!isOpen) setIsAccountOpen(false);
  }, [isOpen]);

  const openAccount = useCallback(() => {
    setIsAccountOpen(!isAccountOpen);
  }, [isAccountOpen]);

  return (
    <>
      <Sheet open={isOpen} onOpenChange={toggle}>
        <SheetTrigger
          className={cn('hover:bg-muted hover:cursor-pointer', className)}
          asChild
        >
          <div className="flex border rounded p-1">
            <MenuIcon />
          </div>
        </SheetTrigger>
        <SheetContent side="left" className="w-72 flex flex-col">
          <div className="px-1 py-6 pt-6">
            <NavList
              items={NavItems}
              setOpen={toggle}
              disabled={disableNavigation}
            />
          </div>
          <div className="px-1 py-2 mt-auto">
            <Accordion type="single" collapsible>
              <AccordionItem value="item-1" className="border rounded">
                <AccordionContent className="pb-0 border-b">
                  {AccountNavItems.map((item) => (
                    <div
                      key={item.title}
                      onClick={() => {
                        navigate({ to: item.href });
                        setIsOpen(false);
                      }}
                      className={cn(
                        buttonVariants({ variant: 'ghost' }),
                        'group relative flex h-12 justify-start gap-x-3 cursor-pointer',
                      )}
                    >
                      <item.icon className="h-5 w-5" />
                      <div className="absolute left-12 text-base duration-200">
                        {t(item.title)}
                      </div>
                    </div>
                  ))}
                  <div
                    key="logout"
                    onClick={handleSignOut}
                    className={cn(
                      buttonVariants({ variant: 'ghost' }),
                      'group relative flex h-12 justify-start gap-x-3 cursor-pointer',
                    )}
                  >
                    <LogOut className="h-5 w-5" />
                    <div className="absolute left-12 text-base duration-200">
                      {t('signOut')}
                    </div>
                  </div>
                </AccordionContent>
                <AccordionTrigger
                  invertedIcon
                  onClick={openAccount}
                  className="flex flex-row gap-3 h-full w-full px-4 py-2 text-base font-medium !outline-none [&svg]:rotate-180"
                >
                  <User className="h-5 w-5 !rotate-0" />
                  {user?.displayName ?? t('myAccount')}
                </AccordionTrigger>
              </AccordionItem>
            </Accordion>
          </div>
        </SheetContent>
      </Sheet>
    </>
  );
};

export default function Sidebar({
  disableNavigation,
  className,
}: SidebarProps) {
  const isDesktop = useMediaQuery(
    `(min-width: ${UIViewLayoutDictionary.desktopMinWidth})`,
  );

  return isDesktop ? (
    <DesktopSidebar
      disableNavigation={disableNavigation}
      className={className}
    />
  ) : (
    <MobileSidebar
      disableNavigation={disableNavigation}
      className={className}
    />
  );
}
