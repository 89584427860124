import { UserProfileSettings } from '@/components/account/profile-info';
import { userProfileQueryOptions } from '@/lib/queries/user';
import { useSuspenseQuery } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/account/profile')({
  component: ProfileRoute,
  loader: ({ context: { queryClient } }) =>
    queryClient.ensureQueryData(userProfileQueryOptions),
});

function ProfileRoute() {
  const {
    data: { user: profile },
  } = useSuspenseQuery(userProfileQueryOptions);

  return <UserProfileSettings profile={profile} />;
}
