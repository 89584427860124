import { AnalyticsStatsSkeleton } from '@/components/analytics/analytics-stats';
import { CoursesAnalytics } from '@/components/analytics/courses-analytics';
import { AxiosErrorComponent } from '@/components/ui/errors/axios-error';
import { courseStatsQueryOptions } from '@/lib/queries/analytics/analytics-course';
import { useSuspenseQuery } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/analytics/_layout_analytics/courses')({
  component: AnalyticsCourses,
  loader: ({ context: { queryClient } }) =>
    queryClient.ensureQueryData(courseStatsQueryOptions),
  pendingMs: 100,
  errorComponent: AxiosErrorComponent,
  pendingComponent: AnalyticsCoursesSkeleton,
});

function AnalyticsCourses() {
  const {
    data: { stats },
  } = useSuspenseQuery(courseStatsQueryOptions);

  return <CoursesAnalytics stats={stats} />;
}

function AnalyticsCoursesSkeleton() {
  return <AnalyticsStatsSkeleton />;
}
