import { UIConfig, UIConfigEnum } from '@/config';
import { UIViewLayoutDictionary } from '@/constants/view-layout';
import { useMediaQuery } from '@react-hook/media-query';
import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

export type SidebarState = {
  isOpen: boolean;
  toggle: () => void;
  setIsOpen: (isOpen: boolean) => void;
};

export const SidebarContext = createContext<SidebarState>({
  isOpen: false,
  toggle: () => {},
  setIsOpen: () => {},
});

const useIsSidebarOpen = () => {
  const isDesktop = useMediaQuery(
    `(min-width: ${UIViewLayoutDictionary.desktopMinWidth})`,
  );

  const [isOpen, setIsOpen] = useState<boolean>(
    isDesktop
      ? UIConfig[UIConfigEnum.SidebarInitialStateDesktop]
      : UIConfig[UIConfigEnum.SidebarInitialStateMobile],
  );

  useEffect(() => {
    setIsOpen(isDesktop);
  }, [isDesktop]);

  return [isOpen, setIsOpen] as const;
};

export function SidebarProvider({ children }: PropsWithChildren) {
  const [isOpen, setIsOpen] = useIsSidebarOpen();

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <SidebarContext.Provider value={{ isOpen: isOpen, toggle, setIsOpen }}>
      {children}
    </SidebarContext.Provider>
  );
}

export function useSidebar(): SidebarState {
  const context = useContext(SidebarContext);
  if (!context) {
    throw new Error('useSidebar must be used within an SidebarProvider');
  }
  return context;
}
