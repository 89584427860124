import { Card } from '@/components/ui/card';
import { cn } from '@/lib/utils';
import { WithClassName } from '@/types/with-classname';
import ResetPasswordForm from './reset.form';

export default function ResetPasswordCard({ className }: WithClassName) {
  return (
    <Card
      className={cn(
        'p-4 shadow-md hover:shadow-xl overflow-hidden transition-shadow duration-300',
        className,
      )}
    >
      <ResetPasswordForm />
    </Card>
  );
}
