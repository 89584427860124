import {
  CardImageLayoutSkeleton,
  CardWithImageLayout,
} from '../layouts/card-image-layout';
import logo from '../../assets/CareWays-logo.png';
import { Course } from '@/lib/models';
import { DefaultItemsLayout } from '../layouts/default-items-layout';

export interface CourseCardProps {
  course: Course;
  onClick: () => void;
}

export const CourseCard = ({ course, onClick }: CourseCardProps) => {
  return (
    <CardWithImageLayout
      title={course.name}
      description={course.description}
      image={course.image ?? logo}
      action={onClick}
    />
  );
};

export const CourseCardSkeleton = () => {
  return <CardImageLayoutSkeleton />;
};

export const CourseCardListSkeleton = () => (
  <DefaultItemsLayout>
    {Array(5)
      .fill(0)
      .map((_, idx) => (
        <CourseCardSkeleton key={idx} />
      ))}
  </DefaultItemsLayout>
);
